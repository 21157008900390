import agromonteApi from '../../services/agromonteApi'

export const getSyncLogs = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/sync_logs', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSyncLog = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/sync_logs', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSyncLog = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/sync_logs/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSyncLog = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/sync_logs/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const retryBouquetSyncLog = data => (
	new Promise((resolve, reject) => { 
		agromonteApi()
			.post('/sync/store_bouquet_from_app', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const retryBedBuildSyncLog = data => (
	new Promise((resolve, reject) => { 
		agromonteApi()
			.post('/sync/store_bed_build_from_app', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)