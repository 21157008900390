import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getCurveDurations = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/curve_durations', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCurveDuration = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/curve_durations', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCurveDuration = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/curve_durations/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCurveDuration = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/curve_durations/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchCurveDurations = async (name, token) => (
fetch( getDomain()+`/curve_durations?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(curve_duration => ({
				label: curve_duration.name,
				value: curve_duration.id,
			})),
		)
)