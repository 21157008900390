import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getTypeCategories = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/type_categories', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTypeCategory = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/type_categories', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTypeCategory = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/type_categories/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTypeCategory = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/type_categories/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchTypeCategories = async (name, token) => (
	fetch( getDomain()+`/type_categories?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(user => ({
				label: user.name,
				value: user.id,
			})),
		)
)