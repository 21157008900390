import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getBedBuilds = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/bed_builds', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeBedBuild = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/bed_builds', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateBedBuild = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/bed_builds/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteBedBuild = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/bed_builds/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchBedBuilds = async (name, token) => (
	fetch( getDomain()+`/bed_builds?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(bed_build => ({
				label: bed_build.name,
				value: bed_build.id,
			})),
		)
)