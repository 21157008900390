import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import FlowerTypeForm from './FlowerTypeForm'

import { updateFlowerType } from '../services'
import { handleError } from '../../../helpers'

export default function EditFlowerTypeModal({ visible, onClose, flowerType, reload }) {
	const [selectedColor, setSelectedColor] = useState( flowerType.flower_color ? {
		label: flowerType.flower_color.name, value: flowerType.flower_color.id
	} : [])
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			type_category_name: flowerType.type_category_name,
			name: flowerType.name,
			code: flowerType.code,
			is_active: flowerType.is_active ? 1 : 0
		}
	})

	const onSubmit = values => {
		if(values.code === flowerType.code) delete values.code
		updateFlowerType(flowerType.id, {
			...values,
			flower_color_id: selectedColor.value
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<FlowerTypeForm 
							isEdit 
							register={register} 
							errors={errors}
							selectedColor={selectedColor}
							setSelectedColor={setSelectedColor}
						/>
						<Button color="primary" type="submit">
							Actualizar Registro
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}