import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

import NewCurveDurationForm from './partials/NewCurveDurationForm'
import CurveDurationsTable from './partials/CurveDurationsTable'

import ListTopBar from '../../components/ListTopBar'

import { getCurveDurations } from './services'
import { handleError } from '../../helpers'

export default function CurveDurationsList() {
	const [curveDurations, setCurveDurations] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!curveDurations && getCurveDurations({ 
			[`filter[${filterType}]`]: filterValue,
			sort: 'name' 
		})
			.then(res => setCurveDurations(res.data.data))
			.catch(error => handleError(error))
	}, [ curveDurations, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Opciones de Duración" 
				subtitle="Administrador las opciones de duración permitidos en la plataforma" 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setCurveDurations(null)}
				options={[
					{ label: 'Buscar por referencia', value: 'name' },
				]}
			/>
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<NewCurveDurationForm 
								reload={() => setCurveDurations(null)}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<CurveDurationsTable 
								curveDurations={curveDurations}
								reload={() => setCurveDurations(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}