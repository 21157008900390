import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditBouquetPlacementModal from './EditBouquetPlacementModal'
import Pagination from '../../../components/Pagination'

import { deleteBouquetPlacement } from '../services'
import { handleError } from '../../../helpers'
import { Col, Row } from 'reactstrap'

export default function BouquetPlacementsTable({ bouquetPlacements, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)

	const handleDelete = id => {
		deleteBouquetPlacement(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Ubicación',
			dataIndex: 'grow_bed',
			render: t => t.location
		},
		{
			title: 'Variedad',
			dataIndex: 'flower_type',
			render: t => `${t.type_category_name}: ${t.name}`
		},
		{
			title: 'Color',
			dataIndex: 'flower_color',
			render: t => t.name
		},
		{
			title: 'Curva',
			dataIndex: 'flower_curve',
			render: t => t.name
		},
		{
			title: 'Cantidades',
			render: (t, r) => `Total: ${r.total_quantity} - Actual: ${r.current_quantity} - Perdidas: ${r.losses}`
		},
		{
			title: 'Fecha Siembra',
			dataIndex: 'sow_at',
			render: t => moment(t).format('YYYY-MM-DD')
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar" onClick={()=>setEditModal(r)}>
						<FontAwesomeIcon className="text-link" icon={faEdit} />
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<Row className='mt-5'>
				<Col>
					<p className='mb-5'><strong>Semanas de Confinamiento:</strong> {r.confinement_weeks}</p>
					<p className='mb-5'><strong>Edad Erradicada:</strong> {r.eradic_age}</p>
				</Col>
				<Col>
					<p className='mb-5'><strong>Semanas Erradicada:</strong> {r.eradic_week}</p>
					<p className='mb-5'><strong>Temporada:</strong> {r.season}</p>
				</Col>
				<Col>
					<p className='mb-5'><strong>Transplantada el:</strong> {moment(r.transplant_at).format('YYYY-MM-DD')}</p>
				</Col>
			</Row>
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table 
				dataSource={bouquetPlacements}
				loading={!bouquetPlacements}
				rowKey={r => r.id}
				columns={columns}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
				size="small"
				pagination={false}
				className="mb-15"
				expandable={{ expandedRowRender }}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editModal && (
				<EditBouquetPlacementModal 
					visible
					onClose={() => setEditModal(null)}
					bouquetPlacement={editModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}