import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faLockOpen, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditRoleModal from './EditRoleModal'

import { deleteRole } from '../services'
import { handleError } from '../../../helpers'
import RolePermissionsModal from './RolePermissionsModal'

export default function RolesTable({ roles, reload }) {
	const [editModal, setEditModal] = useState(null)
	const [permissionsModal, setPermissionsModal] = useState(null)

	const handleDelete = id => {
		deleteRole(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Rol',
			dataIndex: 'name'
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Permisos" onClick={() => setPermissionsModal(r)}>
						<FontAwesomeIcon className='text-link' icon={faLockOpen} />
						<p className='text-link mb-0 ml-5 inline'>Permisos</p>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Editar" onClick={()=>setEditModal(r)}>
						<FontAwesomeIcon className="text-link" icon={faEdit} />
						<p className='text-link mb-0 ml-5 inline'>Editar</p>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
							<p className='text-link mb-0 ml-5 inline'>Eliminar</p>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={roles}
				loading={!roles}
				rowKey={r => r.id}
				columns={columns}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
			/>

			{ editModal && (
				<EditRoleModal 
					visible
					onClose={() => setEditModal(null)}
					role={editModal}
					reload={reload}
				/>
			)}
			{ permissionsModal && (
				<RolePermissionsModal 
					visible
					onClose={() => setPermissionsModal(null)}
					role={permissionsModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}