import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getGrowBeds = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/grow_beds', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeGrowBed = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/grow_beds', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateGrowBed = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/grow_beds/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteGrowBed = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/grow_beds/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchGrowBeds = async (term, token, scopeId) => (
	fetch( getDomain()+`/grow_beds?filter[internal_code]=${term}&filter[growShip.growBlock.code]=${scopeId}&include=growShip.growBlock&per_page=150`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(grow_bed => ({
				label: `Bloque: ${grow_bed.grow_ship.grow_block.code} - Nave: ${grow_bed.grow_ship.code} - Cama: ${grow_bed.internal_code}`,
				value: grow_bed.id,
			})),
		)
)

export const importGrowBeds = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/grow_beds/import', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)