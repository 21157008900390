import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getFlowerCurves = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/flower_curves', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeFlowerCurve = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/flower_curves', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateFlowerCurve = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/flower_curves/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteFlowerCurve = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/flower_curves/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchFlowerCurves = async (name, token, flowerTypeId) => (
	fetch( getDomain()+`/flower_curves?filter[name]=${name}&filter[flower_type_id]=${flowerTypeId}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(flower_curve => ({
				label: flower_curve.name,
				value: flower_curve.id,
			})),
		)
)