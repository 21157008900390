import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import UsersList from './UsersList'

export default function Users() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/users" component={UsersList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}