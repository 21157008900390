import React from 'react'
import { message } from 'antd'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'

import FarmForm from './FarmForm'

import { storeFarm } from '../services'
import { handleError } from '../../../helpers'

export default function NewFarmForm({ reload }) {
	const { handleSubmit, register, formState: { errors }, reset } = useForm({
		defaultValues: {
			is_active: 1
		}
	})

	const onSubmit = values => {
		storeFarm(values)
			.then(res => {
				message.success(res.data.message)
				reset()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<FarmForm 
					register={register} 
					errors={errors}
				/>				
				<Button color='primary' type="submit">
					Crear Registro Nuevo
				</Button>
			</Form>
		</React.Fragment>
	)
}