import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import GrowBedsList from './GrowBedsList'

export default function GrowBeds() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/grow_beds" component={GrowBedsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}