import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import SchedulesList from './SchedulesList'

export default function Schedules() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/schedules" component={SchedulesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}