import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import BedBuildsTable from './partials/BedBuildsTable'
import NewBedBuildModal from './partials/NewBedBuildModal'
import ListTopBar from '../../components/ListTopBar'

import { getBedBuilds } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function BedBuildsList() {
	const [bedBuilds, setBedBuilds] = useState(null)
	const [filterType, setFilterType] = useState('growBed.code')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!bedBuilds && getBedBuilds({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'growBed.growShip.growBlock,builderUser,writerUser'
		})
			.then(res => {
				setBedBuilds(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ bedBuilds, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setBedBuilds(null)}
				title="Construcción de Camas"
				subtitle="Listado de construcciones registradas en la plataforma"
				options={[
					{ label: 'Buscar por código cama', value: 'growBed.code' },
					{ label: 'Buscar por % de base cama metalica', value: 'metal_base_percent' },
					{ label: 'Buscar por malla cuadros recogidos', value: 'recolected_squares' },
				]}
				ctaText="Agregar Construcción Nueva"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<BedBuildsTable
						bedBuilds={bedBuilds} 
						reload={()=>setBedBuilds(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewBedBuildModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setBedBuilds(null)}
				/>
			)}
		</React.Fragment>
	)
}