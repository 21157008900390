import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import UsersTable from './partials/UsersTable'
import NewUserModal from './partials/NewUserModal'

import { getUsers } from './services'
import { handleError } from '../../helpers'

export default function UsersList() {
	const [users, setUsers] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(()=>{
		!users && getUsers({
			include: 'parentUser',
			[`filter[${filterType}]`]: filterValue,
			sort: 'code'
		})
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [users, filterType, filterValue])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Directorio de usuarios"
				subtitle="Usuarios de plataforma y app Agromonte"
				reload={() => setUsers(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por cedula', value: 'document' },
					{ label: 'Buscar por codigo', value: 'code' },
				]}
				ctaText="Agregar Usuario"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<UsersTable 
						users={users} 
						reload={() => setUsers(null)} 
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewUserModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setUsers(null)}
				/>
			)}
		</React.Fragment>
	)
}