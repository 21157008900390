import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import RolesList from './RolesList'

export default function Roles() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/roles" component={RolesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}