import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

import NewFlowerColorForm from './partials/NewFlowerColorForm'
import FlowerColorsTable from './partials/FlowerColorsTable'

import ListTopBar from '../../components/ListTopBar'

import { getFlowerColors } from './services'
import { handleError } from '../../helpers'

export default function FlowerColorsList() {
	const [flowerColors, setFlowerColors] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!flowerColors && getFlowerColors({ 
			[`filter[${filterType}]`]: filterValue,
			sort: 'name' 
		})
			.then(res => setFlowerColors(res.data.data))
			.catch(error => handleError(error))
	}, [ flowerColors, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Colores de Flor" 
				subtitle="Administrador de valores de colores permitidos en la plataforma" 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setFlowerColors(null)}
				options={[
					{ label: 'Buscar por referencia', value: 'name' },
					{ label: 'Buscar por código', value: 'code' },
				]}
			/>
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<NewFlowerColorForm 
								reload={() => setFlowerColors(null)}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<FlowerColorsTable 
								flowerColors={flowerColors}
								reload={() => setFlowerColors(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}