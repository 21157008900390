import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function PermitsForm({ register, errors }) {
	return (
		<React.Fragment>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Inicio <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						type="time"
						{...register('from', { required: true })}
					/>
					{ errors.from && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Finalización <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						type="time"
						{...register('until', { required: true })}
					/>
					{ errors.until && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Observaciones <span className='text-primary'>*</span></Form.Label>
				<Form.Control
					as="textarea"
					{...register('comment', { required: true })}
					style={{ height: '100px' }}
				/>
				{ errors.comment && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}