import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import LogsList from './LogsList'

export default function Logs() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/logs" component={LogsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}