import React from 'react'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchGrowBlocks } from '../../GrowBlocks/services'
import { searchGrowShips } from '../../GrowShips/services'

export default function GrowBedForm({ 
	register, errors, selectedBlock, setSelectedBlock, selectedShip, setSelectedShip 
}) {
	return (
		<React.Fragment>
			{ selectedBlock && (
				<Form.Group>
					<Form.Label>Código de Bloque</Form.Label>
					<ServerSideSelect 
						value={selectedBlock}
						placeholder="Seleccione un bloque"
						fetchOptions={searchGrowBlocks}
						onChange={value => setSelectedBlock(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			{ selectedShip && selectedBlock.value && (
				<Form.Group>
					<Form.Label>Código de Nave</Form.Label>
					<ServerSideSelect 
						value={selectedShip}
						placeholder="Seleccione una nave"
						fetchOptions={searchGrowShips}
						scopeId={selectedBlock.value}
						onChange={value => setSelectedShip(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Código Cama <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					{...register('code', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.code && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Número de Cama (N. Interno) <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					{...register('internal_code', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.internal_code && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Longitud <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					type="number"
					{...register('length', { required: true })}
					step="0.1"
				/>
				{ errors.length && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Estado</Form.Label>
				<Form.Control
					{...register('is_active', { required: true })}
					as="select"
				>
					<option value={1}>Activo</option>
					<option value={0}>Inactivo</option>
				</Form.Control>
			</Form.Group>
		</React.Fragment>
	)
}