import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchGrowBeds } from '../../GrowBeds/services'
import { searchGrowBlocks } from '../../GrowBlocks/services'
import { searchUsers } from '../../Users/services'

export default function BedBuildForm({ 
	register, errors, 
	selectedBlock, setSelectedBlock,
	selectedBed, setSelectedBed, 
	selectedUser, setSelectedUser
}) {
	return (
		<React.Fragment>
			{ selectedBlock && (
				<Form.Group>
					<Form.Label>Código de Bloque</Form.Label>
					<ServerSideSelect 
						value={selectedBlock}
						placeholder="Seleccione una bloque"
						fetchOptions={searchGrowBlocks}
						onChange={value => {
							setSelectedBlock(value)
							setSelectedBed([])
						}}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			{ selectedBed && selectedBlock.label && (
				<Form.Group>
					<Form.Label>Código de Cama</Form.Label>
					<ServerSideSelect 
						value={selectedBed}
						placeholder="Seleccione una cama"
						fetchOptions={searchGrowBeds}
						scopeId={selectedBlock.label}
						onChange={value => setSelectedBed(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			{ selectedUser && (
				<Form.Group>
					<Form.Label>Constructor</Form.Label>
					<ServerSideSelect 
						value={selectedUser}
						placeholder="Seleccione un usuario"
						fetchOptions={searchUsers}
						onChange={value => setSelectedUser(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			<Row>
				<Form.Group as={Col}>
					<Form.Label>% Base Cama Metal <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('metal_base_percent', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="0">0% recogido</option>
						<option value="20">20% recogido</option>
						<option value="50">50% recogido</option>
						<option value="80">80% recogido</option>
						<option value="100">100% recogido</option>
					</Form.Control>
					{ errors.metal_base_percent && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Malla Cuadros Recogidos <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('recolected_squares', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="0">0 Cuadros</option>
						<option value="1">1 Cuadro</option>
						<option value="2">2 Cuadros</option>
						<option value="3">3 Cuadros</option>
						<option value="4">4 Cuadros</option>
					</Form.Control>
					{ errors.recolected_squares && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
		</React.Fragment>
	)
}