import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import NewScheduleModal from './partials/NewScheduleModal'
import SchedulesTable from './partials/SchedulesTable'
import ListTopBar from '../../components/ListTopBar'

import { getSchedules } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function SchedulesList() {
	const [schedules, setSchedules] = useState(null)
	const [filterType, setFilterType] = useState('user.name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 100, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!schedules && getSchedules({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'user,typeCategory'
		})
			.then(res => {
				setSchedules(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ schedules, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setSchedules(null)}
				title="Horarios de Corte"
				subtitle="Listado de horarios registrados en la plataforma"
				options={[
					{ label: 'Buscar por usuario', value: 'user.name' },
				]}
				ctaText="Agregar Registro Nuevo"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<SchedulesTable
						schedules={schedules} 
						reload={()=>setSchedules(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewScheduleModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setSchedules(null)}
				/>
			)}
		</React.Fragment>
	)
}