import React from 'react'
import { Empty, Table } from 'antd'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

export default function LogsTable({ logs, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Fecha Registro',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MM-DD hh:mm A')
		},
		{
			title: 'Usuario',
			dataIndex: 'causer',
			render: t => t ? t.name : 'Usuario eliminado'
		},
		{
			title: 'Descripción',
			dataIndex: 'description'
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={logs}
				loading={!logs}
				rowKey={r => r.id}
				columns={columns}
				size="small"
				pagination={false}
				className="mb-15"
				locale={{ 
					emptyText: <Empty description="No se encontraron registros" />
				}}
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}