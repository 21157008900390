import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import CurveDurationsList from './CurveDurationsList'

export default function CurveDurations() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/curve_durations" component={CurveDurationsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}