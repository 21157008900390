import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { message } from 'antd'

import SyncLogsTable from './partials/SyncLogsTable'
import ListTopBar from '../../components/ListTopBar'

import { deleteSyncLog, getSyncLogs, retryBedBuildSyncLog, retryBouquetSyncLog } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function SyncLogsList() {
	const [syncLogs, setSyncLogs] = useState(null)
	const [filterType, setFilterType] = useState('user.code')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		!syncLogs && getSyncLogs({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
		})
			.then(res => {
				setSyncLogs(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))

	}, [ syncLogs, pagination, filterType, filterValue ])

	const handleAllSync = () => {
		setLoading(true)
		syncLogs.forEach(syncLog => {
			if(syncLog.type.includes('ramo')) {
				retryBouquetSyncLog({...syncLog.payload, is_retry:true})
					.then(res => handleSuccess(res, syncLog.id))
					.catch(error => handleError(error))
			} else if(syncLog.type.includes('cama')) {
				retryBedBuildSyncLog({...syncLog.payload, is_retry:true})
					.then(res => handleSuccess(res, syncLog.id))
					.catch(error => handleError(error))
			}
		})
		setSyncLogs(null)
		setLoading(false)
	}

	const handleSuccess = (res, syncLogId) => {
		message.success(res.data.message)
		deleteSyncLog(syncLogId)
	}

	return (
		<React.Fragment>
			<ListTopBar
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setSyncLogs(null)}
				title="Sincronizaciones pendientes"
				subtitle="Listado de sincronizaciones no finalizadas"
				options={[
					{ label: 'Buscar por codigo usuario', value: 'user.code' },
				]}
				ctaText={`SYNC ${syncLogs ? syncLogs.length : 0} Registros`}
				handleCtaClick={handleAllSync}
				ctaDisabled={loading || (syncLogs && syncLogs.length === 0)}
			/>
			<Card>
				<CardBody>
					<SyncLogsTable
						syncLogs={syncLogs} 
						reload={()=>setSyncLogs(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}