import React, { useState } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import { Alert, message } from 'antd'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { storeDownloadToken } from '../../DownloadTokens/services'
import { searchTypeCategories } from '../../TypeCategories/services'
import { getUri } from '../../../services/agromonteApi'
import { handleError } from '../../../helpers'

export default function DownloadReportModal({ visible, onClose, data }) {
	const user_id = useSelector(state => state.auth.user.id)
	const [loading, setLoading] = useState(false)
	const [fromDate, setFromDate] = useState(false)
	const [untilDate, setUntilDate] = useState(false)
	const [typeCategorySelected, setTypeCategorySelected] = useState([])

	const onSubmit = () => {
		setLoading(true)
		let token = new Date().getTime()
		storeDownloadToken({ type: `Descarga de ${data.title}`, token, user_id })
			.then(() => {
				message.info('Generando reporte en nueva pestaña')
				window.open(`${getUri()}/reports/export/${data.slug}/${token}/${fromDate} 00:00:00/${untilDate} 23:59:59/${typeCategorySelected.value ? typeCategorySelected.value : false}`)
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
			.finally(() => onClose())
	}

	const isValidForm = () => {
		if(loading) return false
		if(data.requireFromDate && !fromDate) return false
		if(data.requireUntilDate && !untilDate) return false
		if(data.requireTypeCategory && !typeCategorySelected.value) return false

		return true
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Descargar {data.title}</ModalHeader>
				<ModalBody>
					<Alert 
						type="info" 
						description="Al descargar el informe se exportara un archivo excel del mismo." 
						className="mb-10"
					/>
					<Row>
						{ data.requireFromDate && (
							<Col>
								<Form.Control 
									type="date"
									value={fromDate}
									onChange={e => setFromDate(e.target.value)}
									required
								/>
							</Col>
						)}
						{ data.requireUntilDate && (
							<Col>
								<Form.Control 
									type="date"
									value={untilDate}
									onChange={e => setUntilDate(e.target.value)}
									required
								/>
							</Col>
						)}
						{ data.requireTypeCategory && (
							<div className="mt-5">
								<ServerSideSelect 
									value={typeCategorySelected}
									onChange={value => setTypeCategorySelected(value)}
									placeholder="Seleccione un tipo de flor"
									fetchOptions={searchTypeCategories}
									style={{ width: '100%', marginBottom: '7px' }}
								/>
							</div>
						)}
					</Row>
					<Button className='mt-10' color="primary" onClick={onSubmit} disabled={!isValidForm()}>
						Descargar{" "}
						{ loading && <Spinner size="sm" className='ml-5' /> }
					</Button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}