import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import GrowBlockForm from './GrowBlockForm'

import { storeGrowBlock } from '../services'
import { handleError } from '../../../helpers'

export default function NewGrowBlockModal({ visible, onClose, farm, reload }) {
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			farm_id: farm.id,
			is_active: 1
		}
	})

	const onSubmit = values => {
		storeGrowBlock(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Registro</ModalHeader>	
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<GrowBlockForm register={register} errors={errors} />
						<Button color="primary" type="submit">
							Agregar Registro	
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}