import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditScheduleModal from './EditScheduleModal'
import SchedulePermitsModal from '../../Permits/SchedulePermitsModal'
import Pagination from '../../../components/Pagination'

import { deleteSchedule } from '../services'
import { handleError } from '../../../helpers'

export default function SchedulesTable({ schedules, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)
	const [permitsModal, setPermitsModal] = useState(null)

	const handleDelete = id => {
		deleteSchedule(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Horario',
			dataIndex: 'type',
			render: t => t === 'bouquets' ? 'Corte Ramos' : 'Const. Camas'
		},
		{
			title: 'Usuario',
			dataIndex: 'user',
			render: t => t ? t.name : <em>Usuario Eliminado</em>
		},
		{
			title: 'Labor',
			dataIndex: 'type_category',
			render: t => `Corte: ${t ? t.name : 'Sin Labor'} (${t ? t.code : ''})`
		},
		{
			title: 'Fecha',
			dataIndex: 'day'
		},
		{
			title: 'Horario',
			render: (t, r) => `${moment(`2022-01-01 ${r.start_at}`).format('hh:mm A')} - ${moment(`2022-01-01 ${r.end_at}`).format('hh:mm A')}`  
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Novedades" onClick={()=>setPermitsModal(r)}>
						<FontAwesomeIcon className="text-link" icon={faCalendarPlus} />
						<p className='text-link inline mb-0 ml-5'>Novedades</p>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Editar" onClick={()=>setEditModal(r)}>
						<FontAwesomeIcon className="text-link" icon={faEdit} />
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={schedules}
				loading={!schedules}
				rowKey={r => r.id}
				columns={columns}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
				size="small"
				pagination={false}
				className="mb-15"
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editModal && (
				<EditScheduleModal 
					visible
					onClose={() => setEditModal(null)}
					schedule={editModal}
					reload={reload}
				/>
			)}
			{ permitsModal && (
				<SchedulePermitsModal 
					visible
					onClose={() => setPermitsModal(null)}
					schedule={permitsModal}
				/>
			)}
		</React.Fragment>
	)
}