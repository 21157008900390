import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

import DownloadReportModal from './partials/DownloadReportModal'

import PageTitle from '../../components/PageTitle'
import MenuWidget from '../../components/MenuWidget'

export default function ReportsDashboard() {
	const [reportModal, setReportModal] = useState(null)

	const reports = [
		{
			title: 'REPORTE DE USUARIOS',
			slug: 'users',
		},
		{
			title: 'REPORTES DE INVENTARIO DE PLANTAS',
			slug: 'bouquetPlacements',
			requireFromDate: true,
		},
		{
			title: 'CLASIFICACION POR BLOQUE, VARIEDAD Y GRADO',
			slug: 'productionBlockTypeGrade',
			requireFromDate: true,
			requireUntilDate: true,
		},
		{
			title: 'CLASIFICACION POR VARIEDAD Y GRADO',
			slug: 'productionTypeGrade',
			requireFromDate: true,
			requireUntilDate: true,
		},
		{
			title: 'CLASIFICACION POR VARIEDAD Y TALLOS POR RAMO',
			slug: 'productionTypeStems',
			requireFromDate: true,
			requireUntilDate: true,
		},
		{
			title: 'CLASIFICACION POR VARIEDAD DIAS DE LA SEMANA (+7 DIAS)',
			slug: 'productionTypeWeekDays',
			requireFromDate: true,
		},
		{
			title: 'CLASIFICACION POR VARIEDAD Y PUNTO DE CORTE',
			slug: 'productionTypeOpening',
			requireFromDate: true,
			requireUntilDate: true,
		},
		{
			title: 'REPORTE RENDIMIENTOS POR FECHA CORTADORES GENERAL',
			slug: 'cuttersPerformance',
			requireFromDate: true,
			requireUntilDate: true,
		},
		{
			title: 'REPORTE RENDIMIENTOS POR FECHA - TIPO FLOR - LÍDER',
			slug: 'cuttersCategoryParent',
			requireFromDate: true,
			requireUntilDate: true,
			requireTypeCategory: true,
		},
		{
			title: 'REPORTE RENDIMIENTOS POR CORTADOR 7 DIAS SEMANA',
			slug: 'cutter7Days',
			requireFromDate: true,
			requireTypeCategory: true,
		},
		{
			title: 'REPORTE RENDIMIENTO CONSTRUCCIÓN CAMAS',
			slug: 'bedBuildersPerformance',
			requireFromDate: true,
			requireUntilDate: true,
		},
		{
			title: 'REPORTE DE REGISTROS DE RAMOS',
			slug: 'bouquetsRecords',
			requireFromDate: true,
		}
	]


	return (
		<React.Fragment>
			<PageTitle title="Reportes Administradores" subtitle="Descarga de reportes administradores de la Plataforma Agromonte" />
			<Row>
				{ reports.map( report => (
					<Col sm="12" md="6" lg="4" className="mb-20" key={report.slug}>
						<MenuWidget
							title={report.title}
							subtitle="Descargar informe en excel"
							icon={faFileDownload}
							onClick={() => setReportModal(report)}
						/>
					</Col>
				)) }
			</Row>
			{ reportModal && (
				<DownloadReportModal 
					visible
					onClose={() => setReportModal(null)}
					data={reportModal}
				/>
			)}
		</React.Fragment>
	)
}