import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import FlowerTypesList from './FlowerTypesList'

export default function FlowerTypes() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/flower_types" component={FlowerTypesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}