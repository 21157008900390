import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditPermitModal from './EditPermitModal'

import { deletePermit } from '../services'
import { handleError } from '../../../helpers'

export default function PermitsTable({ permits, reload, schedule }) {
	const [editModal, setEditModal] = useState(null)

	const handleDelete = id => {
		deletePermit(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Inicio',
			dataIndex: 'from',
			render: t => moment(t).format('hh:mm A')
		},
		{
			title: 'Finalización',
			dataIndex: 'until',
			render: t => moment(t).format('hh:mm A')
		},
		{
			title: 'Comentarios',
			dataIndex: 'comment'
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar" onClick={()=>setEditModal(r)}>
						<FontAwesomeIcon className="text-link" icon={faEdit} />
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={permits}
				loading={!permits}
				rowKey={r => r.id}
				columns={columns}
				locale={{ 
					emptyText: <Empty description="No se encontraron registros" />
				}}
				size="small"
			/>
			{ editModal && (
				<EditPermitModal 
					visible
					onClose={() => setEditModal(null)}
					permit={editModal}
					schedule={schedule}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}