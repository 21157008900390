import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import BouquetForm from './BouquetForm'

import { storeBouquet } from '../services'
import { handleError } from '../../../helpers'

export default function NewBouquetModal({ visible, onClose, reload }) {
	const [selectedCutter, setSelectedCutter] = useState([])
	const [selectedWriter, setSelectedWriter] = useState([])
	const [selectedBlock, setSelectedBlock] = useState([])
	const [selectedGrowBed, setSelectedGrowBed] = useState([])
	const [selectedAmount, setSelectedAmount] = useState([])
	const [selectedGrade, setSelectedGrade] = useState([])
	const [selectedOpening, setSelectedOpening] = useState([])
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			is_active: 1,
			capture_at: moment().format('YYYY-MM-DDTHH:mm'),
		}
	})

	const onSubmit = values => {
		storeBouquet({ 
			...values, 
			cutter_user_id: selectedCutter.value,
			writer_user_id: selectedWriter.value,
			grow_bed_id: selectedGrowBed.value,
			stems_amount_id: selectedAmount.value,
			quality_grade_id: selectedGrade.value,
			opening_id: selectedOpening.value,
			capture_at: moment(values.capture_at).format('YYYY-MM-DD HH:mm'),
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Registro</ModalHeader>	
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<BouquetForm
							register={register} 
							errors={errors} 
							selectedCutter={selectedCutter}
							setSelectedCutter={setSelectedCutter}
							selectedWriter={selectedWriter} 
							setSelectedWriter={setSelectedWriter}
							selectedBlock={selectedBlock}
							setSelectedBlock={setSelectedBlock}
							selectedGrowBed={selectedGrowBed} 
							setSelectedGrowBed={setSelectedGrowBed}
							selectedAmount={selectedAmount}
							setSelectedAmount={setSelectedAmount}
							selectedGrade={selectedGrade} 
							setSelectedGrade={setSelectedGrade}
							selectedOpening={selectedOpening}
							setSelectedOpening={setSelectedOpening}
						/>
						<Button color="primary" type="submit">
							Agregar Registro	
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}