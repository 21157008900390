import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'

import { getCurveDurations } from '../../CurveDurations/services'
import { handleError } from '../../../helpers'

export default function FlowerCurveForm({ register, errors }) {
	const [curveDurations, setCurveDurations] = useState(null)

	useEffect(() => {
		!curveDurations && getCurveDurations()
			.then(res => setCurveDurations(res.data.data))
			.catch(error => handleError(error))
	}, [ curveDurations ])

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Duración <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					{...register('curve_duration', { required: true })}
					placeholder="Escriba aquí..."
					as="select"
				>
					<option value="">:: Seleccione una opción ::</option>
					{ curveDurations && (
						<React.Fragment>
							{ curveDurations.map(duration => (
								<option key={duration.id}>{duration.name}</option>
							)) }
						</React.Fragment>
					)}
				</Form.Control>
				{ errors.curve_duration && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Estado</Form.Label>
				<Form.Control
					{...register('is_active', { required: true })}
					as="select"
				>
					<option value={1}>Activo</option>
					<option value={0}>Inactivo</option>
				</Form.Control>
			</Form.Group>
		</React.Fragment>
	)
}