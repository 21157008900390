import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import BouquetsList from './BouquetsList'

export default function Bouquets() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/bouquets" component={BouquetsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}