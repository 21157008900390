import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

import NewFlowerTypeForm from './partials/NewFlowerTypeForm'
import FlowerTypesTable from './partials/FlowerTypesTable'

import ListTopBar from '../../components/ListTopBar'

import { getFlowerTypes } from './services'
import { handleError } from '../../helpers'

export default function FlowerTypesList() {
	const [flowerTypes, setFlowerTypes] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!flowerTypes && getFlowerTypes({
			[`filter[${filterType}]`]: filterValue,
			include: 'flowerColor',
			sort: 'name'
		})
			.then(res => setFlowerTypes(res.data.data))
			.catch(error => handleError(error))
	}, [ flowerTypes, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Variedades de Flor" 
				subtitle="Administrador de valores de variedades permitidos en la plataforma" 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setFlowerTypes(null)}
				options={[
					{ label: 'Buscar por referencia', value: 'name' },
					{ label: 'Buscar por código', value: 'code' },
				]}
			/>
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<NewFlowerTypeForm 
								reload={() => setFlowerTypes(null)}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<FlowerTypesTable 
								flowerTypes={flowerTypes}
								reload={() => setFlowerTypes(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}