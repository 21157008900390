import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { faBed, faCalendar, faCalendarAlt, faLeaf, faSeedling, faUsers } from '@fortawesome/free-solid-svg-icons'

import PageTitle from '../../components/PageTitle'
import MenuWidget from '../../components/MenuWidget'

import UsersImportModal from './partials/UsersImportModal'
import GrowBedsImportModal from './partials/GrowBedsImportModal'
import BouquetPlacementsImportModal from './partials/BouquetPlacementsImportModal'
import ImportsTable from './partials/ImportsTable'
import SchedulesImportModal from './partials/SchedulesImportModal'
import FlowerTypesImportModal from './partials/FlowerTypesImportModal'
import SchedulesBedsImportModal from './partials/SchedulesBedsImportModal'

import ListTopBar from '../../components/ListTopBar'

import { getImports } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ImportsList() {
	const [imports, setImports] = useState(null)
	const [filterType, setFilterType] = useState('description')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showUsersImport, setShowUsersImport] = useState(false)
	const [showBedsImport, setShowBedsImport] = useState(false)
	const [showPlacementsImport, setShowPlacementsImport] = useState(false)
	const [showSchedulesImport, setShowSchedulesImport] = useState(false)
	const [showSchedulesBedsImport, setShowSchedulesBedsImport] = useState(false)
	const [showFlowerTypeImport, setShowFlowerTypeImport] = useState(false)

	useEffect(() => {
		!imports && getImports({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			sort: '-created_at'
		})
			.then(res => {
				setImports(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ imports, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<PageTitle title="Importar Masivamente" subtitle="Cargue masivo de información a la plataforma" />
			<Row>
				<Col sm="12" md="6" lg="4" className="mb-15">
					<MenuWidget
						icon={faUsers}
						title="Importar Usuarios"
						subtitle="Cargar masivamente usuarios al sistema"
						onClick={() => setShowUsersImport(true)}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-15">
					<MenuWidget 
						icon={faBed}
						title="Importar Camas"
						subtitle={"Cargar masivamente camas al sistema"}
						onClick={() => setShowBedsImport(true)}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-15">
					<MenuWidget 
						icon={faLeaf}
						title="Inventario de Plantas"
						subtitle={"Cargar masivamente el inventario de plantas"}
						onClick={() => setShowPlacementsImport(true)}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-15">
					<MenuWidget 
						icon={faCalendar}
						title="Horarios de Corte Ramos"
						subtitle={"Cargar horarios de corte de ramos"}
						onClick={() => setShowSchedulesImport(true)}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-15">
					<MenuWidget 
						icon={faCalendarAlt}
						title="Horarios de Corte Camas"
						subtitle={"Cargar horarios de constructores de camas"}
						onClick={() => setShowSchedulesBedsImport(true)}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-15">
					<MenuWidget 
						icon={faSeedling}
						title="Variedades de Flor"
						subtitle={"Cargar masivamente variedades de Flor"}
						onClick={() => setShowFlowerTypeImport(true)}
					/>
				</Col>
			</Row>
			<ListTopBar
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setImports(null)}
				options={[
					{ label: 'Buscar por descripcion', value: 'description' },
					{ label: 'Buscar por fecha de inicio', value: 'started_at' },
				]}
				className="simpleCard mb-10"
			/>
			<Card className='simpleCard'>
				<CardBody>
					<ImportsTable
						imports={imports} 
						reload={()=>setImports(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showUsersImport && (
				<UsersImportModal 
					visible
					onClose={() => setShowUsersImport(false)} 
					reload={() => setImports(null)}
				/>
			)}
			{ showBedsImport && (
				<GrowBedsImportModal 
					visible
					onClose={() => setShowBedsImport(false)} 
					reload={() => setImports(null)}
				/>
			)}
			{ showPlacementsImport && (
				<BouquetPlacementsImportModal 
					visible
					onClose={() => setShowPlacementsImport(false)} 
					reload={() => setImports(null)}
				/>
			)}
			{ showSchedulesImport && (
				<SchedulesImportModal 
					visible
					onClose={() => setShowSchedulesImport(false)}
					reload={() => setImports(null)}
				/>
			)}
			{ showSchedulesBedsImport && (
				<SchedulesBedsImportModal
					visible
					onClose={() => setShowSchedulesBedsImport(false)}
					reload={() => setImports(null)}
				/>
			)}
			{ showFlowerTypeImport && (
				<FlowerTypesImportModal 
					visible
					onClose={() => setShowFlowerTypeImport(false)}
					reload={() => setImports(null)}
				/>
			)}
		</React.Fragment>
	)
}