import React from 'react'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchFlowerColors } from '../../FlowerColors/services'
import { searchTypeCategories } from '../../TypeCategories/services'

export default function FlowerTypeForm({ 
	register, errors, isEdit = false, selectedCategory, setSelectedCategory, selectedColor, setSelectedColor
}) {
	return (
		<React.Fragment>
			{ selectedCategory ? (
				<Form.Group>
					<Form.Label className='block'>Tipo de Flor</Form.Label>
					<ServerSideSelect
						value={selectedCategory}
						placeholder="Seleccione un tipo de flor"
						fetchOptions={searchTypeCategories}
						onChange={value => setSelectedCategory(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			) : (
				<Form.Group>
					<Form.Label>Tipo de Flor</Form.Label>
					<Form.Control disabled {...register('type_category_name')} />
				</Form.Group>
			)}
			{ selectedColor && (
				<Form.Group>
					<Form.Label className='block'>Color de Flor</Form.Label>
					<ServerSideSelect
						value={selectedColor}
						placeholder="Seleccione un color"
						fetchOptions={searchFlowerColors}
						onChange={value => setSelectedColor(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Referencia <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Código <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					{...register('code', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.code && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			{ isEdit && (
				<Form.Group>
					<Form.Label>Estado</Form.Label>
					<Form.Control
						{...register('is_active', { required: true })}
						as="select"
					>
						<option value={1}>Activo</option>
						<option value={0}>Inactivo</option>
					</Form.Control>
				</Form.Group>
			)}
		</React.Fragment>
	)
}