import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getGrowBlocks = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/grow_blocks', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeGrowBlock = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/grow_blocks', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateGrowBlock = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/grow_blocks/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteGrowBlock = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/grow_blocks/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchGrowBlocks = async (term, token) => (
	fetch( getDomain()+`/grow_blocks?filter[code]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(grow_block => ({
				label: grow_block.code,
				value: grow_block.id,
			})),
		)
)