import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import TypeCategoryForm from './TypeCategoryForm'

import { updateTypeCategory } from '../services'
import { handleError } from '../../../helpers'

export default function EditTypeCategoryModal({ visible, onClose, typeCategory, reload }) {
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			name: typeCategory.name,
			code: typeCategory.code,
			is_active: typeCategory.is_active ? 1 : 0
		}
	})

	const onSubmit = values => {
		if(values.code === typeCategory.code) delete values.code
		updateTypeCategory(typeCategory.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<TypeCategoryForm isEdit register={register} errors={errors} />
						<Button color="primary" type="submit">
							Actualizar Registro
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}