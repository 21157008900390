import React from 'react'
import { Card, CardHeader, Button, CardTitle, CardSubtitle } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'

export default function ListTopBar({ 
	filterType, 
	setFilterType, 
	filterValue, 
	setFilterValue, 
	reload,
	title,
	subtitle,
	options,
	ctaText,
	handleCtaClick,
	ctaDisabled,
	className,
	prefix,
	onExport,
	hideCard
}) {
	return (
		<React.Fragment>
			<Card className={className}>
				<CardHeader>
					{ options && (
						<div className="float-right mt-10 mb-0">
							<InputGroup>
								{ prefix && (
									<InputGroup.Text>
										{prefix}
									</InputGroup.Text>
								)}
									<Form.Control 
										as="select" 
										className="bg-light"
										value={filterType}
										onChange={e => setFilterType(e.target.value)}
									>
										{options.map((option,i) => 
											<option key={i} value={option.value}>{option.label}</option>
										)}
									</Form.Control>
								<Form.Control 
									placeholder="Escriba aquí" 
									value={filterValue}
									onChange={e => setFilterValue(e.target.value)}
								/>
								<Button className="btn-inputgroup" color={ctaText ? 'dark' : 'primary'} onClick={reload}>Buscar</Button>
								{ ctaText && (
									<Button 
										className="ml-10 mb-5 btn-inputgroup" 
										color="primary" 
										onClick={handleCtaClick}
										disabled={ctaDisabled}
									>
										{ctaText}
									</Button>
								)}
								{ onExport && (
									<Button className="ml-10 mb-5" color="primary" onClick={onExport}>Exportar</Button>
								)}
							</InputGroup>
						</div>
					)}
					{ title && <CardTitle>{title}</CardTitle> }
					{ subtitle && <CardSubtitle>{subtitle}</CardSubtitle> }
				</CardHeader>
			</Card>
		</React.Fragment>
	)
}