import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

import NewTypeCategoryForm from './partials/NewTypeCategoryForm'
import TypeCategoriesTable from './partials/TypeCategoriesTable'

import ListTopBar from '../../components/ListTopBar'

import { getTypeCategories } from './services'
import { handleError } from '../../helpers'

export default function TypeCategoriesList() {
	const [typeCategories, setTypeCategories] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!typeCategories && getTypeCategories({ 
			[`filter[${filterType}]`]: filterValue,
			include: 'flowerTypesCount',
			sort: 'name'
		})
			.then(res => setTypeCategories(res.data.data))
			.catch(error => handleError(error))
	}, [ typeCategories, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Tipos de Flor" 
				subtitle="Administrador de valores de tipos permitidos en la plataforma" 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setTypeCategories(null)}
				options={[
					{ label: 'Buscar por referencia', value: 'name' },
					{ label: 'Buscar por código', value: 'code' },
				]}
			/>
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<NewTypeCategoryForm 
								reload={() => setTypeCategories(null)}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<TypeCategoriesTable 
								typeCategories={typeCategories}
								reload={() => setTypeCategories(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}