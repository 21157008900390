import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getImports = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/imports', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeImport = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/imports', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateImport = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/imports/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteImport = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/imports/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchImports = async (name, token) => (
	fetch( getDomain()+`/imports?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(i => ({
				label: i.name,
				value: i.id,
			})),
		)
)