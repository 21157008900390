import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import NewBouquetPlacementModal from './partials/NewBouquetPlacementModal'
import BouquetPlacementsTable from './partials/BouquetPlacementsTable'
import ListTopBar from '../../components/ListTopBar'

import { getBouquetPlacements } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function BouquetPlacementsList() {
	const [bouquetPlacements, setBouquetPlacements] = useState(null)
	const [filterType, setFilterType] = useState('growBed.code')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 100, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!bouquetPlacements && getBouquetPlacements({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'growBed,flowerType,flowerColor,flowerCurve',
			sort: 'location'
		})
			.then(res => {
				setBouquetPlacements(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ bouquetPlacements, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setBouquetPlacements(null)}
				title="Inventario de Plantas"
				subtitle="Listado de lotes registrados en la plataforma"
				options={[
					{ label: 'Buscar por codigo cama', value: 'growBed.code' },
					{ label: 'Buscar por nombre de flor', value: 'flowerType.typeCategory.name' },
					{ label: 'Buscar por codigo bloque', value: 'growBed.growShip.growBlock.code' },
				]}
				ctaText="Agregar Registro"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<BouquetPlacementsTable
						bouquetPlacements={bouquetPlacements} 
						reload={()=>setBouquetPlacements(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewBouquetPlacementModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setBouquetPlacements(null)}
				/>
			)}
		</React.Fragment>
	)
}