import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getImportEvents = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/import_events', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeImportEvent = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/import_events', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateImportEvent = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/import_events/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteImportEvent = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/import_events/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchImportEvents = async (name, token) => (
	fetch( getDomain()+`/import_events?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(import_event => ({
				label: import_event.name,
				value: import_event.id,
			})),
		)
)