import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import GrowBedForm from './GrowBedForm'

import { storeGrowBed } from '../services'
import { handleError } from '../../../helpers'

export default function NewGrowBedModal({ visible, onClose, reload }) {
	const [selectedBlock, setSelectedBlock] = useState([])
	const [selectedShip, setSelectedShip] = useState([])
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			is_active: 1
		}
	})

	const onSubmit = values => {
		storeGrowBed({ ...values, grow_ship_id: selectedShip.value })
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Registro</ModalHeader>	
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<GrowBedForm 
							register={register} 
							errors={errors} 
							selectedBlock={selectedBlock}
							setSelectedBlock={setSelectedBlock}
							selectedShip={selectedShip}
							setSelectedShip={setSelectedShip}
						/>
						<Button color="primary" type="submit">
							Agregar Registro	
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}