import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import UserForm from './UserForm'

import { storeUser } from '../services'
import { handleError } from '../../../helpers'

export default function NewUserModal({ visible, onClose, reload }) {
	const [selectedRoles, setSelectedRoles] = useState([])
	const [selectedParent, setSelectedParent] = useState([])
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			is_active: 1,
			password: '123456',
			password_confirmation: '123456',
		}
	})

	const onSubmit = values => {
		if(selectedRoles.length === 0) 
			return message.warning('Debe seleccionar al menos un rol')
		
		if(!selectedParent.value) 
			return message.warning('Debe seleccionar un usuario responsable')

		storeUser({ 
			...values, 
			role_names: selectedRoles.map(r => r.label).join(','),
			parent_user_id: selectedParent ? selectedParent.value : null
		})
			.then(() => {
				message.success('Usuario creado exitosamente.')
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Usuario Nuevo</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<UserForm 
							register={register} 
							errors={errors}
							selectedRoles={selectedRoles}
							setSelectedRoles={setSelectedRoles}
							selectedParent={selectedParent}
							setSelectedParent={setSelectedParent}
						/>
						<Button type="submit" color="primary">Crear Usuario</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}