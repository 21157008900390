import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getFlowerColors = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/flower_colors', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeFlowerColor = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/flower_colors', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateFlowerColor = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/flower_colors/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteFlowerColor = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/flower_colors/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchFlowerColors = async (name, token) => (
	fetch( getDomain()+`/flower_colors?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(flower_color => ({
				label: flower_color.name,
				value: flower_color.id,
			})),
		)
)