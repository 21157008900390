import React from 'react'
import { Col, Row } from 'reactstrap'
import QrCode from 'react-qr-code'

export default function CutterUserQr({ id, label }) {
	return (
		<React.Fragment>
			<Row style={{ border: '1px solid #333', borderRadius: 5, padding: 10 }}>				
				<Col className='text-center'>
					<h6 className='mb-5'>Constr. Camas</h6>
					<h6 className='text-muted'>{id}</h6>
					<h5 className='mb-0'>{label}</h5>
				</Col>
				<Col xs="5" className='text-center mb-5'>
					<QrCode value={id} size={100} />					
					<p className='mt-5 mb-0 small'>{id}</p>
				</Col>
			</Row>
		</React.Fragment>
	)
}