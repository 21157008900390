import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import LogsTable from './partials/LogsTable'
import ListTopBar from '../../components/ListTopBar'

import { getLogs } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function LogsList() {
	const [logs, setLogs] = useState(null)
	const [filterType, setFilterType] = useState('causer.name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({ page: 1, per_page: 15, total: 0 })

	useEffect(() => {
		!logs && getLogs({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'causer',
			sort: '-created_at'
		})
			.then(res => {
				setLogs(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ logs, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setLogs(null)}
				title="Historial de Actividad"
				subtitle="Registro de acciones realizadas en la plataforma"
				options={[
					{ label: 'Buscar por nombre del usuario', value: 'causer.name' },
					{ label: 'Buscar por descripción', value: 'description' },
				]}
			/>
			<Card>
				<CardBody>
					<LogsTable
						logs={logs}
						reload={()=>setLogs(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}