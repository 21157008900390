import React from 'react'
import { Form } from 'react-bootstrap'

export default function TypeCategoryForm({ register, errors, isEdit = false }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Referencia <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Código <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					{...register('code', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.code && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			{ isEdit && (
				<Form.Group>
					<Form.Label>Estado</Form.Label>
					<Form.Control
						{...register('is_active', { required: true })}
						as="select"
					>
						<option value={1}>Activo</option>
						<option value={0}>Inactivo</option>
					</Form.Control>
				</Form.Group>
			)}
		</React.Fragment>
	)
}