import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import Logo from '../assets/images/logo_sistemas.jpg'
import MainMenu from './partials/MainMenu'

export default function MainLayout({ children }) {
	const [openMenu, setOpenMenu] = useState(false)
	const user = useSelector(state => state.auth.user)

	return (
		<Container>
			<TopBar className="no-print">
				<LogoContainer>
					<img src={Logo} alt="Logo Agromonte" width="85px" />
				</LogoContainer>
				<FontAwesomeIcon 
					icon={ openMenu ? faTimes : faBars}
					onClick={()=>setOpenMenu(!openMenu)}
					className="nav-toggle-icon mr-20"
				/>
				<FontAwesomeIcon 
					icon={faArrowLeft}
					onClick={()=>window.history.back()}
					className="nav-toggle-icon"
				/>
				<div className="float-right mt-17 hide-sm">
					<p>{user.name}</p>
				</div>
			</TopBar>
			{ openMenu && (
				<MainMenu closeMenu={()=>setOpenMenu(false)} />
			)}
			<MainContent>
				{children}
			</MainContent>
			<Footer className="no-print">
				<p className='small'>Desarrollado por{" "}
					<a href="//smart4.com.co" target="_blank" rel="noreferrer">
						Smart4 Solutions
					</a>
					{" | "}
					<span>Versión 1.4.0</span>
				</p>
			</Footer>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: #f7f8f9;	
`

const TopBar = styled.div`
	width: 100%;
	padding: 5px 30px 0px;
	background-color: #25352c;
	color: #fff;
	position: fixed;
	top: 0px;
	z-index: 10;
`

const MainContent = styled.div`
	padding: 30px;
	width: 100%;
	min-height: 90vh;
	position:relative;
	top: 45px;
`

const Footer = styled.div`
	padding: 30px 20px 20px;
	width: 100%;
	text-align: center;
`

const LogoContainer = styled.div`
	display: inline-block;
	margin-right: 20px;
	margin-bottom: 5px;
	background-color: #fff;
	padding: 5px 7px;
`