import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import NewGrowBedModal from './partials/NewGrowBedModal'
import GrowBedsTable from './partials/GrowBedsTable'
import ListTopBar from '../../components/ListTopBar'

import { getGrowBeds } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function GrowBedsList() {
	const [growBeds, setGrowBeds] = useState(null)
	const [filterType, setFilterType] = useState('code')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 100, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!growBeds && getGrowBeds({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'growShip.growBlock',
			sort: 'location'
		})
			.then(res => {
				setGrowBeds(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ growBeds, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setGrowBeds(null)}
				title="Administrador de Camas"
				subtitle="Listado de camas registradas en la plataforma"
				options={[
					{ label: 'Buscar por código cama', value: 'code' },
					{ label: 'Buscar por código nave', value: 'growShip.code' },
					{ label: 'Buscar por código bloque', value: 'growShip.growBlock.code' },
				]}
				ctaText="Agregar Cama Nueva"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<GrowBedsTable
						growBeds={growBeds} 
						reload={()=>setGrowBeds(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewGrowBedModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setGrowBeds(null)}
				/>
			)}
		</React.Fragment>
	)
}