import React, { useState } from 'react'
import { message } from 'antd'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'

import FlowerTypeForm from './FlowerTypeForm'

import { storeFlowerType } from '../services'
import { handleError } from '../../../helpers'

export default function NewFlowerTypeForm({ reload }) {
	const [selectedCategory, setSelectedCategory] = useState([])
	const [selectedColor, setSelectedColor] = useState([])
	const { handleSubmit, register, formState: { errors }, reset } = useForm({
		defaultValues: {
			is_active: 1
		}
	})

	const onSubmit = values => {
		storeFlowerType({ 
			...values, 
			type_category_id: selectedCategory.value,
			flower_color_id: selectedColor.value
		})
			.then(res => {
				message.success(res.data.message)
				reset()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<FlowerTypeForm 
					register={register} 
					errors={errors}
					selectedCategory={selectedCategory}
					setSelectedCategory={setSelectedCategory}
					selectedColor={selectedColor}
					setSelectedColor={setSelectedColor}
				/>				
				<Button color='primary' type="submit">
					Crear Registro Nuevo
				</Button>
			</Form>
		</React.Fragment>
	)
}