import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import BouquetPlacementsList from './BouquetPlacementsList'

export default function BouquetPlacements() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/bouquet_placements" component={BouquetPlacementsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}