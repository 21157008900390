import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import BouquetsTable from './partials/BouquetsTable'
import ListTopBar from '../../components/ListTopBar'

import { getBouquets } from './services'
import { handleError, parsePagination } from '../../helpers'
import NewBouquetModal from './partials/NewBouquetModal'

export default function BouquetsList() {
	const [bouquets, setBouquets] = useState(null)
	const [filterType, setFilterType] = useState('code')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!bouquets && getBouquets({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'growBed,stemsAmount,qualityGrade,opening,cutterUser,writerUser'
		})
			.then(res => {
				setBouquets(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ bouquets, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setBouquets(null)}
				title="Producción de Ramos"
				subtitle="Listado de ramos registrados en la plataforma"
				options={[
					{ label: 'Buscar por código', value: 'code' },
				]}
				ctaText="Agregar Ramo"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<BouquetsTable
						bouquets={bouquets} 
						reload={()=>setBouquets(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewBouquetModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setBouquets(null)}
				/>
			)}
		</React.Fragment>
	)
}