import React from 'react'
import { Switch, Redirect, Route } from 'react-router'

import QRsGeneratorTool from './QRsGeneratorTool'

export default function QRsGenerator() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/qr_generator" component={QRsGeneratorTool} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}