import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getQualityGrades = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/quality_grades', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeQualityGrade = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/quality_grades', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateQualityGrade = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/quality_grades/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteQualityGrade = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/quality_grades/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchQualityGrades = async (name, token) => (
	fetch( getDomain()+`/quality_grades?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(quality_grade => ({
				label: `${quality_grade.name} (${quality_grade.code})`,
				value: quality_grade.id,
			})),
		)
)