import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { logout } from '../../containers/Authentication/services'
import { userLogout } from '../../containers/Authentication/actions'
import { handleError } from '../../helpers'

export default function MainMenu({ closeMenu }) {
	const user = useSelector(state => state.auth.user)
	const dispatch = useDispatch()

	const onLogout = () => {
		logout()
			.then(() => dispatch(userLogout()))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Container>
				<UserContainer>
					<UserName>{user.name}</UserName>
					<p>c.c. {user.document}</p>
					<p className="mb-0 text-link" onClick={onLogout}>Cerrar Sesión</p>
				</UserContainer>
				<MenuList>
					<li>
						<Link to="/bouquet_placements" onClick={closeMenu}>Inventario de Plantas</Link>
					</li>
					<li>
						<Link to="/bouquets" onClick={closeMenu}>Producción de Ramos</Link>
					</li>
					<li>
						<Link to="/grow_beds" onClick={closeMenu}>Administración de Camas</Link>
					</li>
					<li>
						<Link to="/bed_builds" onClick={closeMenu}>Construcción de Camas</Link>
					</li>
					<li>
						<Link to="/schedules" onClick={closeMenu}>Horarios de Corte y Const.</Link>
					</li>
					{ user.permissions.includes('users_directory') && (
						<li className='mt-15'>
							<Link className='topBorder' to="/users" onClick={closeMenu}>Directorio de Usuarios</Link>
						</li>
					)}
					{ user.permissions.includes('admin_reports') && (
						<li>
							<Link to="/reports" onClick={closeMenu}>Reportes Administradores</Link>
						</li>
					)}
					{ user.permissions.includes('massive_imports') && (
						<li className='mt-15'>
							<Link className='topBorder' to="/imports" onClick={closeMenu}>Importación Masiva</Link>
						</li>
					)}
					{ user.permissions.includes('qr_generator') && (
						<li>
							<Link to="/qr_generator" onClick={closeMenu}>Generador de QRs</Link>
						</li>
					)}
					{ user.permissions.includes('system_logs') && (
						<li>
							<Link to="/logs" onClick={closeMenu}>Historial de Actividad</Link>
						</li>
					)}
					{ user.permissions.includes('system_logs') && (
						<li>
							<Link to="/sync_logs" onClick={closeMenu}>Sincronizaciones Fallidas</Link>
						</li>
					)}
					{ user.permissions.includes('system_settings') && (
						<li className='mt-15'>
							<Link className='topBorder' to="/settings" onClick={closeMenu}>Ajustes del Sistema</Link>
						</li>
					)}
				</MenuList>
				<p>Correo de soporte: soporte@smart4.com.co</p>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	z-index: 9;	
	padding-top: 50px;
	width: 240px;
	background-color: white;
	text-align: center;
	-webkit-box-shadow: 3px 0 12px 0 #b3b3b3;
	box-shadow: 3px 0 12px 0 #b3b3b3;
	overflow-y: scroll;
`

const UserContainer = styled.div`
	padding: 40px 20px 30px;
	background-color: #e8e8e8;
`

const UserName = styled.h6`
	font-size: 17px;
	text-transform: uppercase;
	font-weight: 600;
`

const MenuList = styled.ul`
	list-style: none;
	margin: 0px 0px 30px;
	padding: 0;
	& li {
		padding: 0;
    	margin: 0;
	}
	& li a {
		display: inline-block;
		padding: 14px 0;
		margin: 0;
		border-bottom: 1px solid #e8e8e8;		
		width: 100%;
	}
	& li a.topBorder {
		border-top: 1px solid #e8e8e8;
	}
	& li a:hover {
		cursor: pointer;
		background-color: #25352c;
		color: #fff !important;
	}
`