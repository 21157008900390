import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'
import moment from 'moment'

import UserForm from './UserForm'

import { updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function EditUserModal({ visible, onClose, user, reload }) {
	const [selectedRoles, setSelectedRoles] = useState(user.role_names.split(',').map((r,i) => ({ value: i, label: r })))
	const [selectedParent, setSelectedParent] = useState( user.parent_user ? {
		label: user.parent_user.name, value: user.parent_user_id
 	} : [])
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		defaultValues: {
			...user,
			born_at: moment(user.born_at).format('YYYY-MM-DD'),
			hire_at: moment(user.hire_at).format('YYYY-MM-DD'),
		}
	})

	const onSubmit = values => {
		if(parseInt(values.document) === parseInt(user.document)) delete values.document
		if(values.code === user.code) delete values.code
		if(values.email === user.email) delete values.email
		updateUser(user.id, { 
			...values, 
			role_names: selectedRoles.map(r => r.label).join(','),
			parent_user_id: selectedParent.value 
		})
			.then(() => {
				message.success('Usuario actualizado exitosamente.')
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	const handleClose = () => {
		reset()
		onClose()
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={handleClose}>
				<ModalHeader toggle={handleClose}>Actualizar Usuario</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<UserForm 
							user={user} 
							register={register} 
							errors={errors}
							selectedParent={selectedParent}
							setSelectedParent={setSelectedParent}
							selectedRoles={selectedRoles}
							setSelectedRoles={setSelectedRoles}
						/>
						<Button type="submit" color="primary">Actualizar Usuario</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}