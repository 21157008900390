import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import QualityGradesList from './QualityGradesList'

export default function QualityGrades() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/quality_grades" component={QualityGradesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}