import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import RoleForm from './RoleForm'

import { updateRole } from '../services'
import { handleError } from '../../../helpers'

export default function EditRoleModal({ visible, onClose, role, reload }) {
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			name: role.name,
		}
	})

	const onSubmit = values => {
		updateRole(role.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
				<ModalBody>
					<RoleForm 
						register={register} 
						errors={errors}
						onSubmit={handleSubmit(onSubmit)}
					/>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}