import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getRoles = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/roles', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeRole = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/roles', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateRole = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/roles/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteRole = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/roles/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchRoles = async (term, token) => (
	fetch( getDomain()+`/roles/search/${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(role => ({
				label: role.name,
				value: role.id,
			})),
		)
)

export const toggleRolePermission = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post(`/roles/${id}/permissions`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)
