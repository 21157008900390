import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getBouquetPlacements = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/bouquet_placements', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeBouquetPlacement = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/bouquet_placements', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateBouquetPlacement = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/bouquet_placements/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteBouquetPlacement = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/bouquet_placements/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchBouquetPlacements = async (name, token) => (
	fetch( getDomain()+`/bouquet_placements?filter[growBed.code]=${name}&include=growBed`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(bouquet_placement => ({
				label: `#${bouquet_placement.id} - ${bouquet_placement.grow_bed.location}`,
				value: bouquet_placement.id,
			})),
		)
)

export const importBouquetPlacements = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/bouquet_placements/import', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)