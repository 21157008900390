import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Badge } from 'reactstrap'

import EditGrowBedModal from './EditGrowBedModal'
import Pagination from '../../../components/Pagination'

import { deleteGrowBed } from '../services'
import { handleError } from '../../../helpers'

export default function GrowBedsTable({ growBeds, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)

	const handleDelete = id => {
		deleteGrowBed(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Ubicación',
			dataIndex: 'location',
		},
		{
			title: 'Código Cama',
			dataIndex: 'code',
			render: t => `Cama: ${t}`
		},
		{
			title: 'Cama',
			dataIndex: 'internal_code',
			render: t => `#${t}`
		},
		{
			title: 'Longitud (m)',
			dataIndex: 'length',
			render: t => t ? `${t} metros` : <em className='hint-text'>Sin registro</em>
		},
		{
			title: 'Estado',
			dataIndex: 'is_active',
			render: t => <Badge size="sm" color={t ? 'success' : 'warning'}>{t ? 'Activo' : 'Inactivo'}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 
							onClick={()=>setEditModal(r)} 
						/>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={growBeds}
				loading={!growBeds}
				rowKey={r => r.id}
				columns={columns}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
				size="small"
				pagination={false}
				className="mb-15"
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editModal && (
				<EditGrowBedModal 
					visible
					onClose={() => setEditModal(null)}
					growBed={editModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}