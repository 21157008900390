import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import PermitsTable from './partials/PermitsTable'
import NewPermitModal from './partials/NewPermitModal'

import { getPermits } from './services'
import { handleError } from '../../helpers'

export default function SchedulePermitsModal({ visible, onClose, schedule }) {
	const [permits, setPermits] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!permits && getPermits({
			'filter[schedule_id]': schedule.id,
		})
			.then(res => setPermits(res.data.data))
			.catch(error => handleError(error))
	}, [ schedule, permits ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Novedades en Horario</ModalHeader>
				<ModalBody>
					<p>Novedades en horario <b>{schedule.day}</b> de <b>{schedule.user ? schedule.user.name : <em>Usuario Eliminado</em>}</b></p>
					<PermitsTable 
						permits={permits}
						reload={() => setPermits(null)}
						schedule={schedule}
					/>
					<div className='text-right mt-10'>
						<Button color="primary" onClick={()=>setShowNewModal(true)}>
							Agregar Novedad
						</Button>
					</div>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewPermitModal 
					visible
					onClose={() => setShowNewModal(false)}
					schedule={schedule}
					reload={() => setPermits(null)}
				/>
			)}
		</React.Fragment>
	)
}