import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditUserModal from './EditUserModal'
import { deleteUser } from '../services'
import { handleError } from '../../../helpers'

export default function UsersTable({ users, reload }) {
	const [editUser, setEditUser] = useState(null)
	const user = useSelector(state => state.auth.user)

	const handleDelete = id => {
		deleteUser(id)
			.then(() => {
				message.success('Usuario Eliminado Exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Código',
			dataIndex: 'code'
		},
		{
			title: 'Nombre Completo',
			dataIndex: 'name'
		},
		{
			title: 'Documento',
			dataIndex: 'document'
		},
		{
			title: 'Roles',
			dataIndex: 'role_names'
		},
		{
			title: 'Correo',
			dataIndex: 'email'
		},
		{
			title: 'Responsable',
			dataIndex: 'parent_user',
			render: t => t ? t.name : <em className='hint-text'>Sin responsable</em>
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Usuario">
						<div className='inline' onClick={()=>setEditUser(record)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />{" "}
							<span className='text-link'>Editar</span>
						</div>
					</Tooltip>
					{ user.id !== record.id && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el usuario?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
								<span className='text-link'>Eliminar</span>
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={users}
				columns={columns}
				rowKey={record => record.id}
				size="small"
			/>
			{ editUser && (
				<EditUserModal 
					visible={true}
					onClose={() => setEditUser(null)}
					user={editUser}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}