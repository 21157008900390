import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getFarms = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/farms', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeFarm = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/farms', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateFarm = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/farms/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteFarm = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/farms/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchFarms = async (name, token) => (
fetch( getDomain()+`/farms?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(farm => ({
				label: farm.name,
				value: farm.id,
			})),
		)
)