import React, { useState, useEffect } from 'react'
import { CSVLink } from "react-csv"
import { Button } from 'reactstrap'
import { Divider } from 'antd'
import moment from 'moment'

import { getUsers, searchUsers } from '../../Users/services'
import { handleError } from '../../../helpers'
import ServerSideSelect from '../../../components/ServerSideSelect'

export default function ExportScheduleTemplate() {
	const [selectedUser, setSelectedUser] = useState([])
	const [users, setUsers] = useState(null)

	useEffect(() => {
		!users && selectedUser.value && getUsers({
			'filter[parent_user_id]': selectedUser.value,
			'filter[is_active]': 1
		})
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [ users, selectedUser ])

	const headers = [
		{ label: 'fecha', key: 'fecha' },
		{ label: 'codigo_cortador', key: 'codigo_cortador' },
		{ label: 'nombre_usuario', key: 'nombre_usuario' },
		{ label: 'tipo_flor', key: 'tipo_flor' },
		{ label: 'hora_inicio', key: 'hora_inicio' },
		{ label: 'hora_finalizacion', key: 'hora_finalizacion' },
		{ label: 'minutos_receso', key: 'minutos_receso' },
	]
	
	const data = users ? users.map( user => ({ 
		fecha: moment().format('DD/MM/YYYY'),
		codigo_cortador: user.code,
		nombre_usuario: user.name,
		hora_inicio: '06:00',
		hora_finalizacion: '15:00',
		minutos_receso: '30'
	})) : []

	const csvReport = {
		data: data,
		headers: headers,
		filename: selectedUser.label+' Horarios.csv'
	}

	return (
		<React.Fragment>
			<Divider />
			<h6 className='mb-0'>Exportar Plantilla</h6>
			<ServerSideSelect 
				value={selectedUser}
				placeholder="Seleccione un responsable"
				fetchOptions={searchUsers}
				onChange={value => {
					setUsers(null)
					setSelectedUser(value)
				}}
				style={{ width: '100%', marginBottom: '7px', marginTop: '15px' }}
			/>
			<CSVLink {...csvReport}>
				<Button disabled={!selectedUser.value} color="primary" outline>
					Export Plantilla
				</Button>
			</CSVLink>
		</React.Fragment>
	)
}