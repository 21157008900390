import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Empty } from 'antd'
import { Button, Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import QrCode from 'react-qr-code'

import ServerSideSelect from '../../components/ServerSideSelect'
import NewBouquetQr from './partials/NewBouquetQr'
import CutterUserQr from './partials/CutterUserQr'

import { searchGrowBlocks } from '../GrowBlocks/services'
import { searchStemsAmountsByCode } from '../StemsAmounts/services'
import { searchUsersReturnCode } from '../Users/services'
import { generateQrIds } from './services'
import { handleError } from '../../helpers'
import GrowBedQr from './partials/GrowBedQr'

export default function QRsGeneratorTool() {
	const [ids, setIds] = useState(null)
	const [cutter, setCutter] = useState(null)
	const [stemsAmount, setStemsAmount] = useState(null)
	const [labels, setLabels] = useState(null)
	const [currentType, setCurrentType] = useState(null)
	const [loading, setLoading] = useState(false)
	const [selectedCutterUser, setSelectedCutterUser] = useState([])
	const [selectedStemsAmount, setSelectedStemsAmount] = useState([])
	const [selectedCutterUsers, setSelectedCutterUsers] = useState([])
	const [selectedGrowBlocks, setSelectedGrowBlocks] = useState([])
	const { register, handleSubmit, formState: { errors }, watch } = useForm({
		defaultValues: {
			parameter: 100,
			type: 'new_bouquets'
		}
	})

	const onSubmit = values => {
		setLoading(true)
		setIds(null)
		setCutter(null)
		setStemsAmount(null)
		generateQrIds({
			...values,
			cutter_user_code: selectedCutterUser.value,
			stems_amount_code: selectedStemsAmount.value,
			grow_blocks_ids: selectedGrowBlocks.map(block => block.value).join(','),
			cutter_user_codes: selectedCutterUsers.map(user => user.value).join(','),
		})
			.then(res => {
				setIds(res.data.data.ids)
				setCutter(res.data.data.cutter_user)
				setStemsAmount(res.data.data.stems_amount_code)
				setLabels(res.data.data.labels)
				setCurrentType(res.data.data.type)
				setSelectedCutterUser([])
				setSelectedStemsAmount([])
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	const isValid = () => {
		if(watch('type') === 'new_bouquets'){
			if(selectedCutterUser.value && selectedStemsAmount.value) return true
			else return false
		} else {
			return true
		}
	}

	return (
		<React.Fragment>
			<Card className='no-print'>
				<CardBody style={{ maxWidth: '700px' }}>
					<h5>Generador de QRs</h5>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Control
							as="select"
							{...register('type', { required:true })}
							className="bg-light"
						>
							<option value="new_bouquets">Códigos Ramos Nuevos</option>
							<option value="existing_grow_beds_by_block_code">Códigos de Camas Existentes:</option>
							<option value="cutter_users">Códigos Contr. Camas:</option>
							{/* <option value="existing_users">Códigos de Usuarios Existentes:</option> */}
							{/* <option value="existing_grow_bed_by_code">Códigos de Camas Unico</option> */}
							{/* <option value="existing_user_by_code">Un Usuario de Código:</option> */}
						</Form.Control>
						{ watch('type') === 'new_bouquets' && (
							<React.Fragment>
								<Row className='mt-10'>
									<Col>
										<ServerSideSelect 
											value={selectedCutterUser}
											onChange={value => setSelectedCutterUser(value)}
											placeholder="Seleccione un cortador por código"
											fetchOptions={searchUsersReturnCode}
											style={{ width: '100%', marginBottom: '7px' }}
										/>
									</Col>
									<Col>
										<ServerSideSelect 
											value={selectedStemsAmount}
											onChange={value => setSelectedStemsAmount(value)}
											placeholder="Seleccione una cantidad de tallos"
											fetchOptions={searchStemsAmountsByCode}
											style={{ width: '100%', marginBottom: '7px' }}
										/>
									</Col>
								</Row>
								<InputGroup className='mt-0 mb-5'>
									<InputGroup.Text>Cantidad: </InputGroup.Text>
									<Form.Control {...register('parameter', { required: true })} />
								</InputGroup>
							</React.Fragment>
						)}
						{ watch('type') === 'existing_grow_beds_by_block_code' && (
							<React.Fragment>
								<ServerSideSelect 
									value={selectedGrowBlocks}
									onChange={value => setSelectedGrowBlocks(value)}
									placeholder="Seleccione uno o varios bloques"
									fetchOptions={searchGrowBlocks}
									style={{ width: '100%', marginBottom: '7px' }}
									mode="multiple"
								/>
							</React.Fragment>
						)}
						{ watch('type') === 'cutter_users' && (
							<ServerSideSelect 
								value={selectedCutterUsers}
								onChange={value => setSelectedCutterUsers(value)}
								placeholder="Seleccione uno o varios usuarios"
								fetchOptions={searchUsersReturnCode}
								style={{ width: '100%', marginBottom: '7px' }}
								mode="multiple"
							/>
						)}
						{ errors.parameter && (<Form.Text className='text-warning'>Debe ingresar un valor</Form.Text>)}
						<Button className='btn-inputgroup mt-0' color="primary" type="submit" disabled={!isValid()}>
							Generar QRs
						</Button>
					</Form>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					{ ids ? (
						<React.Fragment>
							{ ids.length > 0 ? (
								<React.Fragment>
									<Row>
										{ ids.map( (id, i) => (
											<Col key={i} sm="3" style={{ background: 'white' }}>
												{ (currentType === 'new_bouquets' && cutter) ? (
													<NewBouquetQr id={id} cutter={cutter} stemsAmount={stemsAmount} />
												) : currentType === 'cutter_users' ? (
													<CutterUserQr id={id} label={labels[i]} />
												) : currentType === 'existing_grow_beds_by_block_code' ? (
													<GrowBedQr id={id} label={labels[i]} />
												) : (
													<React.Fragment>
														<QrCode value={id} size={100} />
														<p className='mt-5 mb-0'>{id}</p>
														{ labels && (
															<p className='mb-0'>{labels[i]}</p>
														)}
													</React.Fragment>
												)}
											</Col>
										)) }
									</Row>
								</React.Fragment>
							) : (
								<Empty description="No se encontraron IDs" />
							)}
						</React.Fragment>
					) : loading ? (
						<Spinner />
					) : (
						<Empty description="Aún no ha generado códigos para imprimir" />
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}