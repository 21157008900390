import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import BouquetPlacementForm from './BouquetPlacementForm'

import { storeBouquetPlacement } from '../services'
import { handleError } from '../../../helpers'

export default function NewBouquetPlacementModal({ visible, onClose, reload }) {
	const [selectedBlock, setSelectedBlock] = useState([])
	const [selectedBed, setSelectedBed] = useState([])
	const [selectedType, setSelectedType] = useState([])
	const [selectedColor, setSelectedColor] = useState([])
	const [selectedCurve, setSelectedCurve] = useState([])
	
	const { handleSubmit, register, formState: { errors }, watch, setValue } = useForm({
		defaultValues: {
			total_quantity: 0,
			losses: 0
		}
	})

	const onSubmit = values => {
		storeBouquetPlacement({ 
			...values, 
			current_quantity: parseInt(values.total_quantity) - parseInt(values.losses),
			grow_bed_id: selectedBed.value,
			flower_type_id: selectedType.value,
			flower_color_id: selectedColor.value,
			flower_curve_id: selectedCurve.value,
			sow_at: moment(values.sow_at).format('YYYY-MM-DD'),
			transplant_at: moment(values.transplant_at).format('YYYY-MM-DD')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Registro</ModalHeader>	
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<BouquetPlacementForm 
							register={register} 
							errors={errors} 
							watch={watch}
							setValue={setValue}
							selectedBlock={selectedBlock}
							setSelectedBlock={setSelectedBlock}
							selectedBed={selectedBed}
							setSelectedBed={setSelectedBed}
							selectedType={selectedType}
							setSelectedType={setSelectedType}
							selectedColor={selectedColor}
							setSelectedColor={setSelectedColor}
							selectedCurve={selectedCurve}
							setSelectedCurve={setSelectedCurve}
						/>
						<Button color="primary" type="submit">
							Agregar Registro
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}