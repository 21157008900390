import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import ScheduleForm from './ScheduleForm'

import { updateSchedule } from '../services'
import { handleError } from '../../../helpers'

export default function EditScheduleModal({ visible, onClose, schedule, reload }) {
	const { handleSubmit, register, formState: { errors }, watch } = useForm({
		defaultValues: {
			start_at: schedule.start_at,
			end_at: schedule.end_at,
			break_minutes: schedule.break_minutes,
			type: schedule.type,
		}
	})

	const onSubmit = values => {
		updateSchedule(schedule.id, {
			...values,
			day: moment(values.day).format('YYYY-MM-DD'),
			start_at: moment(`2022-01-01 ${values.start_at}`).format('HH:mm'),
			end_at: moment(`2022-01-01 ${values.end_at}`).format('HH:mm'),
			total_minutes: moment(`2022-01-01 ${values.end_at}`).diff(`2022-01-01 ${values.start_at}`, 'minutes')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>	
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ScheduleForm 
							register={register} 
							errors={errors} 
							watch={watch}
							schedule={schedule} 
						/>
						<Button color="primary" type="submit">
							Actualizar Registro	
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}