import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import GrowBlocksTable from './partials/GrowBlocksTable'

import { getGrowBlocks } from './services'
import { handleError } from '../../helpers'
import NewGrowBlockModal from './partials/NewGrowBlockModal'

export default function FarmGrowBlocksModal({ visible, onClose, farm, reload }) {
	const [growBlocks, setGrowBlocks] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!growBlocks && getGrowBlocks({ 
			'filter[farm_id]': farm.id,
			sort: 'code-unsigned',
		})
			.then(res => setGrowBlocks(res.data.data))
			.catch(error => handleError(error))
	}, [ growBlocks, farm ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Bloques en {farm.name}</ModalHeader>
				<ModalBody>
					<div className='text-right mb-10'>
						<Button color="primary" type="submit" onClick={() => setShowNewModal(true)}>
							Agregar Registro Nuevo
						</Button>
					</div>
					<GrowBlocksTable 
						growBlocks={growBlocks}
						reload={() => setGrowBlocks(null)}
					/>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewGrowBlockModal 
					visible
					onClose={() => setShowNewModal(false)}
					farm={farm}
					reload={() => setGrowBlocks(null)}
				/>
			)}
		</React.Fragment>
	)
}