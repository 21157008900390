import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import authReducer from '../containers/Authentication/reducer'

const authPersistConfig = {
	key: 'token',
	storage: storage
}
 
const rootReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer),
})
 
export default rootReducer