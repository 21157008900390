import React from 'react'
import { Table } from 'antd'

import Pagination from '../../../components/Pagination'

export default function ImportEventsTable({ importEvents, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Fila',
			render: (t, r, i) => i + 1
		},
		{
			title: 'Resultado',
			render: (t, r) => `${r.is_success ? 'Exitoso' : 'Fallido'}`
		},
		{
			title: 'Comentarios',
			dataIndex: 'description'
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={importEvents}
				loading={!importEvents}
				columns={columns}
				rowKey={r => r.id}
				pagination={false}
				size="small"
				className='mb-15'
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
		</React.Fragment>
	)
}