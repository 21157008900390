import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faLockOpen, faTractor, faLeaf, faSeedling, faBrush, faCheckDouble, faHourglassStart, faPlusCircle, faClock } from '@fortawesome/free-solid-svg-icons'

import PageTitle from '../../components/PageTitle'
import MenuWidget from '../../components/MenuWidget'

export default function Settings() {
	return (
		<React.Fragment>
			<PageTitle title="Ajustes del Sistema" subtitle="Gestione los ajustes y elementos predetermidados de la plataforma Agromonte" />
			<Row>
				<Col sm="12" md="6" lg="3" className="mb-15">
					<MenuWidget
						icon={faLockOpen}
						title="Roles y Permisos"
						subtitle="Administrar los accesos de cada rol a la plataforma y app móvil"
						linkTo={"/roles"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-15">
					<MenuWidget 
						icon={faTractor}
						title="Cultivos, Bloques y Naves"
						subtitle={"Administrar localizaciones de los cultivos"}
						linkTo={"/farms"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-15">
					<MenuWidget 
						icon={faLeaf}
						title="Tipos de Flores"
						subtitle={"Crear, actualizar y eliminar tipos de flores predefinidas en el sistema"}
						linkTo={"/type_categories"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-15">
					<MenuWidget 
						icon={faSeedling}
						title="Variedades de Flores"
						subtitle={"Crear, actualizar y eliminar variedades de flores predefinidas en el sistema"}
						linkTo={"/flower_types"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-15">
					<MenuWidget 
						icon={faBrush}
						title="Colores de Flores"
						subtitle={"Crear, actualizar y eliminar colores de flores predefinidos en el sistema"}
						linkTo={"/flower_colors"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-15">
					<MenuWidget 
						icon={faHourglassStart}
						title="Aperturas de Cortes"
						subtitle={"Crear, actualizar y eliminar aperturas predefinidas en el sistema"}
						linkTo={"/openings"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-15">
					<MenuWidget 
						icon={faCheckDouble}
						title="Grado Calidad de Cortes"
						subtitle={"Crear, actualizar y eliminar grado-calidad de cortes"}
						linkTo={"/quality_grades"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-15">
					<MenuWidget 
						icon={faPlusCircle}
						title="Cantidades de Tallos"
						subtitle={"Valores predeterminados para las cantidades de tallos disponibles en el sistema"}
						linkTo={"/stems_amounts"}
					/>
				</Col>
				<Col sm="12" md="6" lg="3" className="mb-15">
					<MenuWidget 
						icon={faClock}
						title="Opciones de Duraciones"
						subtitle={"Opciones permitidas de selección de duraciones"}
						linkTo={"/curve_durations"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}