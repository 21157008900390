import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import FlowerCurvesTable from './partials/FlowerCurvesTable'
import NewFlowerCurveModal from './partials/NewFlowerCurveModal'

import { getFlowerCurves } from './services'
import { handleError } from '../../helpers'

export default function TypeFlowerCurvesModal({ visible, onClose, flowerType }) {
	const [flowerCurves, setFlowerCurves] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!flowerCurves && getFlowerCurves({
			'filter[flower_type_id]': flowerType.id
		})
			.then(res => setFlowerCurves(res.data.data))
			.catch(error => handleError(error))
	}, [ flowerCurves, flowerType ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Curvas para {flowerType.name}</ModalHeader>
				<ModalBody>
					<div className='text-right mb-10'>
						<Button color="primary" type="submit" onClick={() => setShowNewModal(true)}>
							Agregar Registro Nuevo
						</Button>
					</div>
					<FlowerCurvesTable 
						flowerCurves={flowerCurves}
						reload={() => setFlowerCurves(null)}
					/>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewFlowerCurveModal
					visible
					onClose={() => setShowNewModal(false)}
					flowerType={flowerType}
					reload={() => setFlowerCurves(null)}
				/>
			)}
		</React.Fragment>
	)
}