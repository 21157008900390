import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getStemsAmounts = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/stems_amounts', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeStemsAmount = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/stems_amounts', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateStemsAmount = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/stems_amounts/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteStemsAmount = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/stems_amounts/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchStemsAmounts = async (name, token) => (
	fetch( getDomain()+`/stems_amounts?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(stems_amount => ({
				label: stems_amount.name,
				value: stems_amount.id,
			})),
		)
)

export const searchStemsAmountsByCode = async (term, token) => (
	fetch( getDomain()+`/stems_amounts?filter[code]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(stems_amount => ({
				label: stems_amount.code,
				value: stems_amount.code,
			})),
		)
)