import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getSchedules = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/schedules', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSchedule = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/schedules', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSchedule = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/schedules/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSchedule = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/schedules/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchSchedules = async (name, token) => (
	fetch( getDomain()+`/schedules?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(schedule => ({
				label: schedule.name,
				value: schedule.id,
			})),
		)
)

export const importBouquetsSchedules = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/schedules/bouquets/import', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const importBedsSchedules = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/schedules/beds/import', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)