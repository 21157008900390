import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import BouquetForm from './BouquetForm'

import { updateBouquet } from '../services'
import { handleError } from '../../../helpers'

export default function EditBouquetModal({ visible, onClose, bouquet, reload }) {
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			code: bouquet.code,			
			serial: bouquet.serial,			
			capture_at: moment(bouquet.capture_at).format('YYYY-MM-DDTHH:mm'),
		}
	})

	const onSubmit = values => {
		if(values.code === bouquet.code) delete values.code
		updateBouquet(bouquet.id, { ...values,
			capture_at: moment(values.capture_at).format('YYYY-MM-DD HH:mm'),
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>	
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<BouquetForm register={register} errors={errors} />
						<Button color="primary" type="submit">
							Actualizar Registro
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}	