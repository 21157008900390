import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'reactstrap'
import moment from 'moment'

import EditBouquetModal from './EditBouquetModal'
import Pagination from '../../../components/Pagination'

import { deleteBouquet } from '../services'
import { handleError } from '../../../helpers'

export default function BouquetsTable({ bouquets, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)

	const handleDelete = id => {
		deleteBouquet(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{	
			title: 'Código',
			dataIndex: 'code'
		},
		{
			title: 'Ubicación',
			dataIndex: 'grow_bed',
			render: t => t.location
		},
		{
			title: 'Cantidad',
			dataIndex: 'stems_amount',
			render: t => t.name
		},
		{
			title: 'Grado Calidad',
			dataIndex: 'quality_grade',
			render: t => `${t.name} (${t.code})`
		},
		{
			title: 'Apertura',
			dataIndex: 'opening',
			render: t => `${t.name} (${t.code})`
		},
		{
			title: 'Cortador',
			dataIndex: 'cutter_user',
			render: t => t ? t.name : 'Usuario eliminado'
		},
		{
			title: 'Hora Sync',
			dataIndex: 'capture_at',
			render: t => moment(t).format('YYYY-MM-DD hh:mm a')
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar" onClick={() => setEditModal(r)}>
						<FontAwesomeIcon className='text-link' icon={faEdit} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<Row className='mt-5'>
				<Col>
					<p className='mt-5 mb-5'><strong>Serial: </strong>{r.serial}</p>
					<p className='mb-5'><strong>Fecha Captura: </strong>{moment(r.capture_at).format('YYYY-MM-DD hh:mm a')}</p>
				</Col>
				<Col>
					<p className='mb-5'><strong>Escritor: </strong>{r.writer_user ? r.writer_user.name : 'Usuario Eliminado'}</p>
				</Col>
			</Row>
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table 
				dataSource={bouquets}
				loading={!bouquets}
				rowKey={r => r.id}
				columns={columns}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
				size="small"
				pagination={false}
				className="mb-15"
				expandable={{ expandedRowRender }}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editModal && (
				<EditBouquetModal 
					visible
					onClose={() => setEditModal(null)}
					bouquet={editModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}