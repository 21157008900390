import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getPermits = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/permits', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storePermit = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/permits', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updatePermit = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/permits/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deletePermit = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/permits/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchPermits = async (name, token) => (
	fetch( getDomain()+`/permits?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(permit => ({
				label: permit.name,
				value: permit.id,
			})),
		)
)