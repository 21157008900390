import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchRoles } from '../../Roles/services'
import { searchUsers } from '../services'

export default function UserForm({ 
	user, register, errors, selectedRoles, setSelectedRoles, selectedParent, setSelectedParent 
}) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre Completo <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', { required:true })} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Código <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('code', { required:true })} />
					{ errors.code && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Documento de Identidad <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('document', { required:true })} />
					{ errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Correo Electrónico <small>(opcional)</small></Form.Label>
				<Form.Control type="email" {...register('email', {})} />
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Fecha Nacimiento <span className="text-danger">*</span></Form.Label>
					<Form.Control type="date" {...register('born_at', { required: true })} />
					{ errors.born_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Fecha Contratación <span className="text-danger">*</span></Form.Label>
					<Form.Control type="date" {...register('hire_at', { required:true })} />
					{ errors.hire_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Rol del Usuario <span className='text-primary'>*</span></Form.Label>
				<ServerSideSelect 
					value={selectedRoles}
					placeholder="Buscar por nombre del rol"
					fetchOptions={searchRoles}
					onChange={value => setSelectedRoles(value)}
					style={{ width: '100%', marginBottom: '7px' }}
					mode="multiple"
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Usuario Responsable <span className='text-primary'>*</span></Form.Label>
				<ServerSideSelect 
					value={selectedParent}
					placeholder="Buscar por nombre"
					fetchOptions={searchUsers}
					onChange={value => setSelectedParent(value)}
					style={{ width: '100%', marginBottom: '7px' }}
				/>
			</Form.Group>
			<hr className='my-10' />
			{ !user ? (
				<React.Fragment>
					<Form.Group>
						<Form.Label>Contraseña <span className="text-danger">*</span></Form.Label>
						<Form.Control {...register("password", {required:true})} placeholder="Mínimo 6 digitos" type="password" />
						{ errors.password && <Form.Text className="text-warning">Ingrese una contraseña de 6 dígitos o más.</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Confirmar contraseña <span className="text-danger">*</span></Form.Label>
						<Form.Control {...register("password_confirmation", {required:true})} placeholder="Ingresar nuevamente" type="password" />
						{ errors.password_confirmation && <Form.Text className="text-warning">Las contraseñas no coinciden.</Form.Text> }
					</Form.Group>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Form.Group>
						<Form.Label>Nueva Contraseña <small>(opcional)</small></Form.Label>
						<Form.Control {...register("new_password", {})} placeholder="Escriba aquí..." type="password" />
					</Form.Group>
					<Form.Group>
						<Form.Label>Estado</Form.Label>
						<Form.Control as="select" {...register('is_active', { required: true })} >
							<option value={1}>Activo</option>
							<option value={0}>Inactivo</option>
						</Form.Control>
					</Form.Group>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}