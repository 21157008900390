import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import BouquetPlacementForm from './BouquetPlacementForm'

import { updateBouquetPlacement } from '../services'
import { handleError } from '../../../helpers'

export default function EditBouquetPlacementModal({ visible, onClose, bouquetPlacement, reload }) {
	const { handleSubmit, register, formState: { errors }, watch, setValue } = useForm({
		defaultValues: {
			total_quantity: bouquetPlacement.total_quantity,
			confinement_weeks: bouquetPlacement.confinement_weeks,
			season: bouquetPlacement.season,
			eradic_age: bouquetPlacement.eradic_age,
			eradic_week: bouquetPlacement.eradic_week,
			losses: bouquetPlacement.losses,
		}
	})

	const onSubmit = values => {
		updateBouquetPlacement(bouquetPlacement.id, {
			...values,
			current_quantity: parseInt(values.total_quantity) - parseInt(values.losses),
			sow_at: moment(values.sow_at).format('YYYY-MM-DD'),
			transplant_at: moment(values.transplant_at).format('YYYY-MM-DD')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>	
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<BouquetPlacementForm 
							register={register} 
							errors={errors} 
							watch={watch} 
							setValue={setValue} 
						/>
						<Button color="primary" type="submit">
							Actualizar Registro	
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}