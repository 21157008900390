import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getFlowerTypes = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/flower_types', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getFlowerType = (id, params) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get(`/flower_types/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeFlowerType = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/flower_types', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateFlowerType = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/flower_types/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteFlowerType = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/flower_types/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchFlowerTypes = async (name, token) => (
	fetch( getDomain()+`/flower_types?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(flower_type => ({
				label: `${flower_type.type_category_name}: ${flower_type.name}`,
				value: flower_type.id,
			})),
		)
)

export const importFlowerTypes = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/flower_types/import', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)