import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { DatePicker } from "@progress/kendo-react-dateinputs"
import '@progress/kendo-theme-default/dist/all.css'
import moment from 'moment'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { searchFlowerColors } from '../../FlowerColors/services'
import { searchFlowerCurves } from '../../FlowerCurves/services'
import { getFlowerType, searchFlowerTypes } from '../../FlowerTypes/services'
import { searchGrowBeds } from '../../GrowBeds/services'
import { handleError } from '../../../helpers'
import { searchGrowBlocks } from '../../GrowBlocks/services'

export default function BouquetPlacementForm({ 
	register, 
	errors, 
	watch,
	setValue,
	selectedBlock, setSelectedBlock,
	selectedBed, setSelectedBed,
	selectedType, setSelectedType,
	selectedColor, setSelectedColor,
	selectedCurve, setSelectedCurve
}) {
	const getFlowerColorDefault = id => {
		getFlowerType(id)
			.then(res => {
				if(res.data.data.flower_color){
					setSelectedColor({ 
						label: res.data.data.flower_color.name, 
						value: res.data.data.flower_color.id,
						disabled: true
					})
				}
			})
			.catch(error => handleError(error))
	}

	const sumLockdownWeeks = () => {
		let fechaSiembra = watch('sow_at') ? moment(watch('sow_at')) : null
		let fechaTransplante = watch('transplant_at') ? moment(watch('transplant_at')) : null

		if(!fechaSiembra || !fechaTransplante) {
			setValue('confinement_weeks', null)
			return 0
		}

		let result = fechaSiembra.diff(fechaTransplante, 'weeks') * -1
		setValue('confinement_weeks', result)

		return result
	}

	const getEradicationWeeks = () => {
		let fechaSiembra = watch('sow_at') ? moment(watch('sow_at')) : null
		let edadErradicacion = watch('eradic_age') ? parseInt(watch('eradic_age')) : null

		if(!fechaSiembra || !edadErradicacion) {
			setValue('eradic_week', null)
			return 0
		}

		let fechaErradicacion = fechaSiembra.add(edadErradicacion, 'weeks')

		let result = fechaErradicacion.format('YYWW')
		setValue('eradic_week', result)

		return result
	}

	return (
		<React.Fragment>
			{ selectedBlock && (
				<Form.Group>
					<Form.Label>Código de Bloque</Form.Label>
					<ServerSideSelect 
						value={selectedBlock}
						placeholder="Seleccione una bloque"
						fetchOptions={searchGrowBlocks}
						onChange={value => {
							setSelectedBlock(value)
							setSelectedBed([])
						}}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			{ selectedBed && selectedBlock.label && (
				<Form.Group>
					<Form.Label>Cama <span className='text-primary'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedBed}
						placeholder="Buscar por codigo"
						fetchOptions={searchGrowBeds}
						scopeId={selectedBlock.label}
						onChange={value => setSelectedBed(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			{ selectedType && (
				<Form.Group>
					<Form.Label>Variedad de Flor <span className='text-primary'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedType}
						placeholder="Buscar por nombre"
						fetchOptions={searchFlowerTypes}
						onChange={value => {
							setSelectedColor([])
							setSelectedType(value)
							getFlowerColorDefault(value.value)
						}}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			{ selectedCurve && (
				<Form.Group>
					<Form.Label>Curva <span className='text-primary'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedCurve}
						placeholder="Buscar por nombre"
						fetchOptions={searchFlowerCurves}
						scopeId={selectedType.value}
						onChange={value => setSelectedCurve(value)}
						style={{ width: '100%', marginBottom: '7px' }}
						disabled={!selectedType.value}
					/>
				</Form.Group>
			)}
			{ selectedColor && (
				<Form.Group>
					<Form.Label>Color <span className='text-primary'>*</span></Form.Label>
					{ (selectedType && selectedColor.disabled) ? (
						<Form.Control value={selectedColor.label} disabled={true} />
					) : (
						<ServerSideSelect
							value={selectedColor}
							placeholder="Buscar por nombre"
							fetchOptions={searchFlowerColors}
							onChange={value => setSelectedColor(value)}
							style={{ width: '100%', marginBottom: '7px' }}
							disabled={!selectedType.value}
						/>
					)}
				</Form.Group>
			)}
			{ selectedColor && (
				<Row>
					<Form.Group as={Col}>
						<Form.Label>Fecha Siembra <span className='text-primary'>*</span></Form.Label>
						<DatePicker weekNumber={true} {...register('sow_at', { required: true })} />
						{ errors.sow_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Fecha Transplante <span className='text-primary'>*</span></Form.Label>
						<DatePicker weekNumber={true} {...register('transplant_at', { required: true })} />
						{ errors.transplant_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
				</Row>
			)}
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Cantidad Total <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_quantity', { required: true })}
					/>
					{ errors.total_quantity && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Perdidas <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('losses', { required: true })}
					/>
					{ errors.losses && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Cantidad Actual <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						value={parseInt(watch('total_quantity')) - parseInt(watch('losses'))}
						disabled
					/>
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Temporada <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						{...register('season', { required: true })}
					/>
					{ errors.season && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Edad Erradicada <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('eradic_age', { required: true })}
					/>
					{ errors.eradic_age && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Semanas de Confinamiento <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						type="number"
						disabled
						value={sumLockdownWeeks()}
					/>
					{ errors.confinement_weeks && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Semana de Erradicación <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						type="number"
						disabled
						value={getEradicationWeeks()}
					/>
					{ errors.eradic_week && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
		</React.Fragment>
	)
}