import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import {store, persistor} from './store'

import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css'
import './assets/css/helpers.css'
import './assets/css/main.css'

Bugsnag.start({
	apiKey: 'd6df7b349cd73a32a3a0af6e3c17392b',
	plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
	  .createErrorBoundary(React)	

const app = (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<HashRouter>
				<ErrorBoundary>
					<App />
				</ErrorBoundary>
			</HashRouter>
		</PersistGate>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));