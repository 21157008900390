import React, { useState } from 'react'
import { Empty, Table, Tooltip } from 'antd'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'

import Pagination from '../../../components/Pagination'
import ImportEventsModal from '../../ImportEvents/ImportEventsModal'

export default function ImportsTable({ imports, reload, pagination, setPagination }) {
	const [eventsModal, setEventsModal] = useState(null)
	
	const columns = [
		{
			title: 'Descripción',
			dataIndex: 'description'
		},
		{
			title: 'Fecha de Inicio',
			dataIndex: 'started_at',
			render: t => moment(t).format('YYYY-MMM-DD hh:mm A')
		},
		{
			title: 'Fecha de Finalización',
			dataIndex: 'ended_at',
			render: t => moment(t).format('YYYY-MMM-DD hh:mm A')
		},
		{
			title: 'Total Registros',
			dataIndex: 'results',
			render: t => t.total
		},
		{
			title: 'Validos',
			dataIndex: 'results',
			render: t => t.valid
		},
		{
			title: 'Exitosos',
			dataIndex: 'results',
			render: t => t.successfull
		},
		{
			title: 'Fallidos',
			dataIndex: 'results',
			render: t => t.total - t.successfull
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Ver registros" onClick={() => setEventsModal(r)}>
						<FontAwesomeIcon className='text-link' icon={faFile} />
						<p className='text-link inline mb-0 ml-5'>Registros</p>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={imports}
				rowKey={r => r.id}
				loading={!imports}
				columns={columns}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
				size="small"
				pagination={false}
				className="mb-15"
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
			{ eventsModal && (
				<ImportEventsModal 
					visible
					onClose={() => setEventsModal(null)}
					importId={eventsModal.id}
				/>
			)}
		</React.Fragment>
	)
}