import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

import NewFarmForm from './partials/NewFarmForm'
import FarmsTable from './partials/FarmsTable'
import PageTitle from '../../components/PageTitle'

import { getFarms } from './services'
import { handleError } from '../../helpers'

export default function FarmsList() {
	const [farms, setFarms] = useState(null)

	useEffect(() => {
		!farms && getFarms({})
			.then(res => setFarms(res.data.data))
			.catch(error => handleError(error))
	}, [ farms ])

	return (
		<React.Fragment>
			<PageTitle 
				title="Cultivos" 
				subtitle="Administrador las localizaciones registradas en la plataforma" 
			/>
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<NewFarmForm 
								reload={() => setFarms(null)}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<FarmsTable 
								farms={farms}
								reload={() => setFarms(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}