import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchGrowBeds } from '../../GrowBeds/services'
import { searchGrowBlocks } from '../../GrowBlocks/services'
import { searchOpenings } from '../../Openings/services'
import { searchQualityGrades } from '../../QualityGrades/services'
import { searchStemsAmounts } from '../../StemsAmounts/services'
import { searchUsers } from '../../Users/services'

export default function BouquetForm({ 
	register, errors,
	selectedCutter, setSelectedCutter, 
	selectedWriter, setSelectedWriter,
	selectedBlock, setSelectedBlock,
	selectedGrowBed, setSelectedGrowBed,
	selectedAmount, setSelectedAmount,
	selectedGrade, setSelectedGrade,
	selectedOpening, setSelectedOpening,
}) {
	return (
		<React.Fragment>
			{ selectedBlock && (
				<Form.Group>
					<Form.Label>Código de Bloque</Form.Label>
					<ServerSideSelect 
						value={selectedBlock}
						placeholder="Seleccione una bloque"
						fetchOptions={searchGrowBlocks}
						onChange={value => {
							setSelectedBlock(value)
							setSelectedGrowBed([])
						}}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			{ selectedGrowBed && selectedBlock.label && (
				<Form.Group>
					<Form.Label>Ubicación de la Cama <span className='text-primary'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedGrowBed}
						placeholder="Buscar por codigo cama"
						fetchOptions={searchGrowBeds}
						scopeId={selectedBlock.label}
						onChange={value => setSelectedGrowBed(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			<Row>
				{ selectedCutter && (
					<Form.Group as={Col}>
						<Form.Label>Cortador <span className='text-primary'>*</span></Form.Label>
						<ServerSideSelect 
							value={selectedCutter}
							placeholder="Buscar por nombre"
							fetchOptions={searchUsers}
							onChange={value => setSelectedCutter(value)}
							style={{ width: '100%', marginBottom: '7px' }}
						/>
					</Form.Group>
				)}
				{ selectedWriter && (
					<Form.Group as={Col}>
						<Form.Label>Escritor <span className='text-primary'>*</span></Form.Label>
						<ServerSideSelect 
							value={selectedWriter}
							placeholder="Buscar por nombre"
							fetchOptions={searchUsers}
							onChange={value => setSelectedWriter(value)}
							style={{ width: '100%', marginBottom: '7px' }}
						/>
					</Form.Group>
				)}
			</Row>
			<Row>
				{ selectedAmount && (
					<Form.Group as={Col}>
						<Form.Label>Cantidad Tallo <span className='text-primary'>*</span></Form.Label>
						<ServerSideSelect 
							value={selectedAmount}
							placeholder="Buscar por nombre"
							fetchOptions={searchStemsAmounts}
							onChange={value => setSelectedAmount(value)}
							style={{ width: '100%', marginBottom: '7px' }}
						/>
					</Form.Group>
				)}
				{ selectedGrade && (
					<Form.Group as={Col}>
						<Form.Label>Grado Calidad <span className='text-primary'>*</span></Form.Label>
						<ServerSideSelect 
							value={selectedGrade}
							placeholder="Buscar por nombre"
							fetchOptions={searchQualityGrades}
							onChange={value => setSelectedGrade(value)}
							style={{ width: '100%', marginBottom: '7px' }}
						/>
					</Form.Group>
				)}
			</Row>
			{ selectedCutter && (
				<Form.Group>
					<Form.Label>Apertura <span className='text-primary'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedOpening}
						placeholder="Buscar por nombre"
						fetchOptions={searchOpenings}
						onChange={value => setSelectedOpening(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Fecha del Corte<span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					type="datetime-local"
					{...register('capture_at', { required: true })}
				/>
				{ errors.capture_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Código <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						{...register('code', { required: true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.code && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Serial <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						{...register('serial', { required: true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.serial && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
		</React.Fragment>
	)
}