import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Alert, message, Switch } from 'antd'

import { toggleRolePermission } from '../services'
import { handleError } from '../../../helpers'

export default function RolePermissionsModal({ visible, onClose, role, reload }) {

	const onToggle = permission => {
		toggleRolePermission(role.id, { permission })
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	const permissions = [
		{
			title: 'Ajustes del Sistema',
			slug: 'system_settings'
		},
		{
			title: 'Directorio de Usuarios',
			slug: 'users_directory'
		},
		{
			title: 'Historial de Actividad',
			slug: 'system_logs'
		},
		{
			title: 'Reportes Administradores',
			slug: 'admin_reports',
		},
		{
			title: 'Importar Masivamente',
			slug: 'massive_imports',
		},
		{
			title: 'Generador de QRs',
			slug: 'qr_generator',
		},
		{
			title: 'App: Registrar Ramos',
			slug: 'app_register_bouquets'
		},
		{
			title: 'App: Registrar C. Camas',
			slug: 'app_register_bed_builds'
		},
		{
			title: 'App: Acceso a Escaners',
			slug: 'app_scan_qrs'
		}
	]

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Permisos Rol {role.name}</ModalHeader>	
				<ModalBody>
					<Alert description="Los cambios se ven reflajos cuando el usuario inicia sesión de nuevo en el administrador o en aplicativo móvil" type="warning" className='mb-15' />
					{ permissions.map((permission, i) => (
						<div className='mb-10' key={i}>
							<Switch 
								checked={role.permissions.includes(permission.slug)} 
								onChange={() => onToggle(permission.slug)} 
							/>
							{" "}{permission.title}
						</div>
					)) }
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}