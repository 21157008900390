import React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'

export default function RoleForm({ register, errors, onSubmit }) {
	return (
		<React.Fragment>
			<Form.Label>Nombre <span className='text-primary'>*</span></Form.Label>
			<InputGroup>
				<Form.Control 
					{...register('name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				<Button color='primary' className='btn-inputgroup mt-0' onClick={onSubmit}>
					Guardar
				</Button>
			</InputGroup>
			{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
		</React.Fragment>
	)
}