import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router'

export default function MenuWidget({ title, subtitle, icon, linkTo, onClick }) {
	const history = useHistory()

	return (
		<Container onClick={onClick ? onClick : () => history.push(linkTo)}>
			<div className="clearfix">
				<div className="float-left mr-2 mb-4">
					<FontAwesomeIcon icon={icon} className="icon" />
				</div>
				<div>
					<h6 className="mb-0">{title}</h6>
					<p className="mb-0 hint-text">{subtitle}</p>
				</div>
			</div>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fff;
	padding: 20px;
	border: 3px solid #fff;
	min-height: 120px;
	&:hover {
		cursor: pointer;
		background-color: #f8f8f8;
	}
	& .icon {
		font-size: 25px;
		color: #e78035;
	}
`