import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditFlowerColorModal from './EditFlowerColorModal'

import { deleteFlowerColor } from '../services'
import { handleError } from '../../../helpers'
import { Badge } from 'reactstrap'

export default function FlowerColorsTable({ flowerColors, reload }) {
	const [editModal, setEditModal] = useState(null)

	const handleDelete = id => {
		deleteFlowerColor(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Referencia',
			dataIndex: 'name'
		},
		{
			title: 'Código',
			dataIndex: 'code'
		},
		{
			title: 'Estado',
			dataIndex: 'is_active',
			render: t => <Badge size="sm" color={t ? 'success' : 'warning'}>{t ? 'Activo' : 'Inactivo'}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 
							onClick={()=>setEditModal(r)} 
						/>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={flowerColors}
				loading={!flowerColors}
				rowKey={r => r.id}
				columns={columns}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
			/>

			{ editModal && (
				<EditFlowerColorModal 
					visible
					onClose={() => setEditModal(null)}
					flowerColor={editModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}