import React from "react"
import { useSelector } from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'

import MainLayout from "./layouts/MainLayout"

import Authentication from './containers/Authentication'
import Error404 from './containers/ErrorsPages/Error404'
import Settings from "./containers/Settings"
import Roles from './containers/Roles'
import Farms from './containers/Farms'
import TypeCategories from './containers/TypeCategories'
import FlowerTypes from './containers/FlowerTypes'
import FlowerColors from './containers/FlowerColors'
import Openings from './containers/Openings'
import QualityGrades from './containers/QualityGrades'
import StemsAmounts from './containers/StemsAmounts'
import Users from "./containers/Users"
import GrowBeds from "./containers/GrowBeds"
import BedBuilds from "./containers/BedBuilds"
import Bouquets from "./containers/Bouquets"
import Schedules from "./containers/Schedules"
import BouquetPlacements from "./containers/BouquetPlacements"
import Logs from "./containers/Logs"
import Imports from "./containers/Imports"
import QRsGenerator from "./containers/QRsGenerator"
import Reports from "./containers/Reports"
import CurveDurations from "./containers/CurveDurations"
import SyncLogs from "./containers/SyncLogs"

export default function App() {	
	const token = useSelector(state => state.auth.token)

	const handleOnIdle = event => {
		if(token) window.location.href = '/#/auth/logout'
	}

	useIdleTimer({
		timeout: 1000 * 60 * 60 * 4,
		onIdle: handleOnIdle,
		debounce: 500
	})
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/bouquets" />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/bouquet_placements" component={BouquetPlacements} />
					<Route path="/bouquets" component={Bouquets} />
					<Route path="/grow_beds" component={GrowBeds} />
					<Route path="/bed_builds" component={BedBuilds} />
					<Route path="/schedules" component={Schedules} />
					<Route path="/users" component={Users} />
					<Route path="/logs" component={Logs} />
					<Route path="/imports" component={Imports} />
					<Route path="/qr_generator" component={QRsGenerator} />
					<Route path="/reports" component={Reports} />

					<Route path="/settings" component={Settings} />
					<Route path="/roles" component={Roles} />
					<Route path="/farms" component={Farms} />
					<Route path="/type_categories" component={TypeCategories} />
					<Route path="/flower_types" component={FlowerTypes} />
					<Route path="/flower_colors" component={FlowerColors} />
					<Route path="/openings" component={Openings} />
					<Route path="/quality_grades" component={QualityGrades} />
					<Route path="/stems_amounts" component={StemsAmounts} />
					<Route path="/curve_durations" component={CurveDurations} />
					<Route path="/sync_logs" component={SyncLogs} />
					
					<Route exact path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}