import React from 'react'
import { Divider, message } from 'antd'
import { useForm } from 'react-hook-form'

import RoleForm from './RoleForm'

import { storeRole } from '../services'
import { handleError } from '../../../helpers'

export default function NewRoleForm({ reload }) {
	const { handleSubmit, register, formState: { errors }, reset } = useForm({
		defaultValues: {}
	})

	const onSubmit = values => {
		storeRole(values)
			.then(res => {
				message.success(res.data.message)
				reset()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<h6>Agregar Nuevo Rol</h6>
			<Divider className='mb-10 mt-5' />
			<RoleForm 
				register={register} 
				errors={errors}
				onSubmit={handleSubmit(onSubmit)}
			/>
		</React.Fragment>
	)
}