import React from 'react'
import { Col } from 'reactstrap'
import QrCode from 'react-qr-code'
import styled from 'styled-components'

export default function GrowBedQr({ id, label }) {
	return (
		<React.Fragment>
			<RowContainer className='row print-friendly'>
				<Col className='text-center'>
					<h5 className='mb-0'>{id}</h5>
					<QrCode value={id.toString()} size={123} />					
					<h5 className='mb-0'>{label}</h5>
				</Col>
			</RowContainer>
		</React.Fragment>
	)
}

const RowContainer = styled.div`
	border-radius: 5px;
	border: 2px solid #333;
	padding: 5px;
	@media print {
		break-inside: avoid;
		page-break-after: always;
	}
`