import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

import NewRoleForm from './partials/NewRoleForm'
import RolesTable from './partials/RolesTable'
import PageTitle from '../../components/PageTitle'

import { getRoles } from './services'
import { handleError } from '../../helpers'

export default function RolesList() {
	const [roles, setRoles] = useState(null)

	useEffect(() => {
		!roles && getRoles({})
			.then(res => setRoles(res.data.data))
			.catch(error => handleError(error))
	}, [ roles ])

	return (
		<React.Fragment>
			<PageTitle 
				title="Roles y Permisos" 
				subtitle="Administrador de roles y permisos permitidos en la plataforma" 
			/>
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<NewRoleForm reload={() => setRoles(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<RolesTable 
								roles={roles}
								reload={() => setRoles(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}