import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import FarmsList from './FarmsList'

export default function Farms() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/farms" component={FarmsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}