import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import RetrySyncLogModal from './RetrySyncLogModal'
import Pagination from '../../../components/Pagination'

import { deleteSyncLog } from '../services'
import { handleError } from '../../../helpers'

export default function SyncLogsTable({ syncLogs, reload, pagination, setPagination }) {
	const [retryModal, setRetryModal] = useState(null)

	const handleDelete = id => {
		deleteSyncLog(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{	
			title: 'Tipo',
			dataIndex: 'type'
		},
		{
			title: 'Fecha',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MM-DD hh:mm A')
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Reintentar" onClick={() => setRetryModal(r)}>
						<FontAwesomeIcon className='text-link' icon={faRedo} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = (r) => (
		<React.Fragment>
			{ Object.entries(r.payload).map(([key, value]) => (
				<p><strong>{key}</strong>: {value}</p>
			)) }
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table 
				dataSource={syncLogs}
				loading={!syncLogs}
				rowKey={r => r.id}
				columns={columns}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
				size="small"
				pagination={false}
				className="mb-15"
				expandable={{ expandedRowRender }}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ retryModal && (
				<RetrySyncLogModal
					visible
					onClose={() => setRetryModal(null)}
					syncLog={retryModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}