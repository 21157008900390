import agromonteApi, { getDomain } from '../../services/agromonteApi'

export const getGrowShips = params => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.get('/grow_ships', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeGrowShip = data => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.post('/grow_ships', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateGrowShip = (id, data) => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.put(`/grow_ships/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteGrowShip = id => (
	new Promise((resolve, reject) => {
		agromonteApi()
			.delete(`/grow_ships/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchGrowShips = async (name, token, scopeId) => (
	fetch( getDomain()+`/grow_ships?filter[code]=${name}&filter[grow_block_id]=${scopeId}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(grow_ship => ({
				label: grow_ship.location,
				value: grow_ship.id,
			})),
		)
)