import React, { useState } from 'react'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import { retryBouquetSyncLog, retryBedBuildSyncLog, deleteSyncLog } from '../services'
import { handleError } from '../../../helpers'

export default function RetrySyncLogModal({ visible, onClose, syncLog, reload }) {
	const [loading, setLoading] = useState(false)
	const [messageForm, setMessageForm] = useState('Al enviar este formulario esta intentando crear o actualizar el registro seleccionado')

	const onSubmit = () => {
		setLoading(true)
		if(syncLog.type.includes('ramo')) {
			retryBouquetSyncLog({...syncLog.payload, is_retry:true})
				.then(res => handleSuccess(res))
				.catch(error => handleFailure(error))
		} else if(syncLog.type.includes('cama')) {
			retryBedBuildSyncLog({...syncLog.payload, is_retry:true})
				.then(res => handleSuccess(res))
				.catch(error => handleFailure(error))
		}
	}

	const handleFailure = error => {
		setLoading(false)
		handleError(error)
	}

	const handleSuccess = res => {
		message.success(res.data.message)
		setMessageForm('Registro sincronizado exitosamente')
		deleteSyncLog(syncLog.id)
			.then(() => {
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Reintentar Sincronización</ModalHeader>	
				<ModalBody>
					<Alert color="warning">{messageForm}</Alert>
					<Button color="primary" onClick={onSubmit} disabled={loading}>
						Reintentar el registro
					</Button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}	