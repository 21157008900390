import React from 'react'
import { Form } from 'react-bootstrap'

export default function CurveDurationForm({ register, errors, isEdit = false }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}