import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import ImportEventsTable from './partials/ImportEventsTable'

import { getImportEvents } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ImportEventsModal({ visible, onClose, importId }) {
	const [importEvents, setImportEvents] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!importEvents && getImportEvents({
			...pagination,
			'filter[import_id]': importId
		})
			.then(res => {
				setImportEvents(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ importEvents, pagination, importId ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Registros de Importación</ModalHeader>	
				<ModalBody>
					<ImportEventsTable 
						importEvents={importEvents}
						reload={() => setImportEvents(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}