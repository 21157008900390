import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import ScheduleForm from './ScheduleForm'

import { storeSchedule } from '../services'
import { handleError } from '../../../helpers'

export default function NewScheduleModal({ visible, onClose, reload }) {
	const [selectedUser, setSelectedUser] = useState([])
	const [selectedCategory, setSelectedCategory] = useState([])
	const { handleSubmit, register, formState: { errors }, watch } = useForm({
		defaultValues: {
			type: 'bouquets'
		}
	})

	const onSubmit = values => {
		storeSchedule({ 
			...values, 
			user_id: selectedUser.value,
			type_category_id: selectedCategory.value,
			day: moment(values.day).format('YYYY-MM-DD'),
			start_at: moment(`2022-01-01 ${values.start_at}`).format('HH:mm'),
			end_at: moment(`2022-01-01 ${values.end_at}`).format('HH:mm'),
			total_minutes: moment(`2022-01-01 ${values.end_at}`).diff(`2022-01-01 ${values.start_at}`, 'minutes')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Registro</ModalHeader>	
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ScheduleForm 
							register={register} 
							errors={errors} 
							watch={watch}
							selectedCategory={selectedCategory}
							setSelectedCategory={setSelectedCategory}
							selectedUser={selectedUser}
							setSelectedUser={setSelectedUser}
						/>
						<Button color="primary" type="submit">
							Agregar Registro	
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}