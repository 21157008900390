import React from 'react'
import { Col, Row } from 'reactstrap'
import QrCode from 'react-qr-code'

export default function NewBouquetQr({ id, cutter, stemsAmount }) {
	return (
		<React.Fragment>
			<Row style={{ border: '1px solid #333', borderRadius: 5, padding: 10 }}>
				<Col xs="5" className='text-center mb-5'>
					<QrCode value={id} size={100} />					
				</Col>
				<Col className='text-center'>
					<h6 className='mb-5'>{cutter.name}</h6>
					<h6 className='text-muted'>{cutter.code}</h6>
					<h5 className='mb-0'>{stemsAmount}</h5>
				</Col>
				<p className='mt-5 mb-0 small'>{id}</p>
			</Row>
		</React.Fragment>
	)
}