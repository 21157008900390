import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import GrowShipsTable from './partials/GrowShipsTable'
import NewGrowShipModal from './partials/NewGrowShipModal'

import { getGrowShips } from './services'
import { handleError } from '../../helpers'

export default function BlockGrowShipsModal({ visible, onClose, growBlock, reload }) {
	const [growShips, setGrowShips] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!growShips && getGrowShips({ 
			'filter[grow_block_id]': growBlock.id,
			sort: 'code-unsigned'
		})
			.then(res => setGrowShips(res.data.data))
			.catch(error => handleError(error))
	}, [ growShips, growBlock ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Naves en Bloque {growBlock.code}</ModalHeader>
				<ModalBody>
					<div className='text-right mb-10'>
						<Button color="primary" type="submit" onClick={() => setShowNewModal(true)}>
							Agregar Registro Nuevo
						</Button>
					</div>
					<GrowShipsTable 
						growShips={growShips}
						reload={() => setGrowShips(null)}
					/>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewGrowShipModal 
					visible
					onClose={() => setShowNewModal(false)}
					growBlock={growBlock}
					reload={() => setGrowShips(null)}
				/>
			)}
		</React.Fragment>
	)
}