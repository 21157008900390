import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditBedBuildModal from './EditBedBuildModal'
import Pagination from '../../../components/Pagination'

import { deleteBedBuild } from '../services'
import { handleError } from '../../../helpers'

export default function BedBuildsTable({ bedBuilds, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)

	const handleDelete = id => {
		deleteBedBuild(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Ubicación',
			dataIndex: 'grow_bed',
			render: t => `BL: ${t.grow_ship.grow_block.code} - NV: ${t.grow_ship.code} - CM: ${t.internal_code} - ID: ${t.code}`
		},
		{
			title: 'Cod. Const.',
			dataIndex: 'builder_user',
			render: t => t ? t.code : <em>Usuario Eliminado</em>
		},
		{
			title: 'Constructor',
			dataIndex: 'builder_user',
			render: t => t ? t.name : <em>Usuario Eliminado</em>
		},
		{
			title: '% Base Cama Metal',
			dataIndex: 'metal_base_percent',
			render: t => `${t}%`
		},
		{
			title: 'Malla Cuadros Recogidos',
			dataIndex: 'recolected_squares',
			render: t => `${t} recogido`
		},
		{
			title: 'Fecha Captura',
			dataIndex: 'capture_at',
			render: t => t ? moment(t).format('YYYY-MM-DD HH:mm') : <em>Sin registro</em>
		},
		{
			title: 'Hora Sync',
			dataIndex: 'created_at',
			render: t => t ? moment(t).format('YYYY-MM-DD HH:mm') : <em>Sin registro</em>
		},
		{
			title: 'Digitador',
			dataIndex: 'writer_user',
			render: t => t ? t.name : <em>Usuario Eliminado</em>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 
							onClick={()=>setEditModal(r)} 
						/>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={bedBuilds}
				loading={!bedBuilds}
				rowKey={r => r.id}
				columns={columns}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
				size="small"
				pagination={false}
				className="mb-15"
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editModal && (
				<EditBedBuildModal 
					visible
					onClose={() => setEditModal(null)}
					bedBuild={editModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}