import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { DatePicker } from "@progress/kendo-react-dateinputs"
import '@progress/kendo-theme-default/dist/all.css'
import moment from 'moment'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchTypeCategories } from '../../TypeCategories/services'
import { searchUsers } from '../../Users/services'

export default function ScheduleForm({ 
	register, errors, watch, selectedUser, setSelectedUser, selectedCategory, setSelectedCategory, schedule
}) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Tipo de Horario</Form.Label>
				<Form.Control
					as="select"
					{...register('type', { required: true })}
					disabled={!selectedUser}
				>
					<option value="bouquets">Corte de Ramos</option>
					<option value="bed_builds">Construcción de Camas</option>
				</Form.Control>
			</Form.Group>
			{ selectedUser && (
				<Form.Group>
					<Form.Label>Usuario</Form.Label>
					<ServerSideSelect 
						value={selectedUser}
						placeholder="Buscar por nombre"
						fetchOptions={searchUsers}
						onChange={value => setSelectedUser(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			{ selectedCategory && watch('type') === 'bouquets' && (
				<Form.Group>
					<Form.Label>Tipo de Flor</Form.Label>
					<ServerSideSelect 
						value={selectedCategory}
						placeholder="Buscar por nombre"
						fetchOptions={searchTypeCategories}
						onChange={value => setSelectedCategory(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Fecha <span className='text-primary'>*</span></Form.Label>
				<DatePicker 
					weekNumber={true} 
					{...register('day', { required: true })} 
					defaultValue={schedule ? moment(schedule.day).toDate() : null}
				/>
				{ errors.day && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Inicio <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						type="time"
						{...register('start_at', { required: true })}
					/>
					{ errors.start_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Finalización <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						type="time"
						{...register('end_at', { required: true })}
					/>
					{ errors.end_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Minutos de Receso <span className='text-primary'>*</span></Form.Label>
				<Form.Control
					type="number"
					{...register('break_minutes', { required: true })}
				/>
				{ errors.break_minutes && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}